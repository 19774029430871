import styled from "styled-components";

export const SalesHeader = styled.div<{ $inputColor?: string }>`
  background: ${props => props.$inputColor || "#3c6cdd"};
  height: 45px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  padding: 0.25em 1em;
  font-weight: 600;
`;

export const Card = styled.div`
  width: 100%
  display: flex;
`;

export const CardHeader = styled.div<{ $inputColor?: string }>`
  background: ${props => props.$inputColor || "#3c6cdd"};
  height: 35px;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
  color: #fff;
  align-items: center;
  font-size: 1em;
  font-weight: 550;
`;

export const CardBody = styled.div<{ $inputColor?: string }>`
  background: ${props => props.$inputColor || "#3c6cdd"};
  height: 70px;
  justify-content: space-between;
  vertical-align: middle;
  line-height: 70px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.8em;
  padding: 0.25em 1em;
  font-weight: 550;
`;