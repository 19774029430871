import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import appRoutes from "../../routes/appRoutes";
import colorConfigs from "../../configs/colorConfigs";
import { Drawer, List, Toolbar, IconButton, Divider, Box } from "@mui/material";

const drawerWidth = 290;

function Menu(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user_type = localStorage.getItem("user_type");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        {appRoutes.map((route, index) => (
          (route.sidebarProps && (route.user_type === "all" || route.user_type === user_type)) ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
        <Toolbar
            sx={{ 
                backgroundColor: colorConfigs.sidebar.bg, 
                flexGrow: 1, 
                paddingLeft: "0px",
                paddingRight: "0px",
            }}
        >
          <div style={{ position: 'relative' }}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' }, marginLeft: 0, marginRight: 0 }}
            >
                <MenuIcon sx={{
                    color: colorConfigs.sidebar.color
                }}/>
            </IconButton>
          </div>
        </Toolbar>
      <Box
        component="nav"
        sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                borderRight: "0px",
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.color,
                marginTop: "55px"
              }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', width: drawerWidth,
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.color,
                marginTop: "55px" 
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Menu;