import CardHeader from "@material-ui/core/CardHeader";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import _ from "lodash";
import {
    makeStyles
  } from "@material-ui/core/styles";
  
const useStyles = makeStyles((theme) => ({
    root: {
      display: "inline-block",
      borderRadius: 16
    },
    expand: {
      transform: "rotate(0deg)",
      marginTop: -10,
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.short
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: "#ECECF4"
    }
}));

function TreeCard({ org, collapsed, onCollapse }) {
    const classes = useStyles();

    return (
        <div
          style={{ 
            display: "inline-block",
            borderRadius: 16,
            backgroundColor: "#CBE2EB"
          }}
        >
          <CardHeader
            avatar={
              <Tooltip
                title={`${_.size(
                  org?.organizationChildRelationship
                )} Sub Profile`}
                arrow
              >
                <Badge
                  style={{ cursor: "pointer" }}
                  color="secondary"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  showZero
                  invisible={!collapsed}
                  overlap="circular"
                  badgeContent={_.size(org?.organizationChildRelationship)}
                  onClick={onCollapse}
                >
                  <Avatar className={classes.avatar}>
                    <PersonOutline color="primary" />
                  </Avatar>
                </Badge>
              </Tooltip>
            }
            title={org?.name}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Status: {org?.ambassador_status}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Account ID: {org?.account_id}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Personal Volume: {org?.personal_volume}
            </Typography>
          </CardContent>
        </div>
    );
  };
  
  export default TreeCard;