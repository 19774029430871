import { axiosAuth, getHeaders } from '../../api/axios';

export function getWalletHistoryData() {

    const transactionsData = getUserTransactions().then(data => adaptMyWalletTransactionsTableData(data))

    return transactionsData
}

const getUserTransactions = async () => {

    //TODO
    //get real information about ambassador and network    
    //const user_id = localStorage.getItem("user_id");
    try {
        const TRANSACTIONS_URL = 'transactions?wallet_from=WALLET_ID&wallet_to=WALLET_ID&inclusive=False';
        const user_object = JSON.parse(localStorage.getItem("user_object"));
  
        const { data } = await axiosAuth.get( TRANSACTIONS_URL.replaceAll('WALLET_ID', user_object.wallet_id), { headers: getHeaders() } );
  
        return data
        
    } catch (err) {
        const error = err
        const data = error.response?.data
        if(data?.error === "No transactions found") {
            return []
        }
        else {
            console.error(error);
            return []
        }
        
    }
  }

  function adaptMyWalletTransactionsTableData (data) {
    var all = [];
    if(data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            var cur = data[i];
            all.push({"operation_type": "Transfer", "date": cur.transaction_datetime, "amount": cur.amount, "transaction_id": cur.transaction_id});
        }
    } else {
        all = [{"operation_type": "No transactions", "date": "", "amount": "", "transaction_id": ""}]
    }

    return all.sort((a, b) => (a.transaction_id > b.transaction_id ? -1 : 1));

  }