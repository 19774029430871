import { useState, useEffect } from 'react';
import { StyledContainer, StyledDivPage } from "../../../components/common/StyledSection";
import NetworkTable from '../../../components/common/StyledTable'
import styled from "styled-components";
import { getNetworkTableData } from '../services'

interface UserInterface {
  id: number,
  level: number,
  account_id: string,
  first_name: string,
  last_name: string,
  email: string,
  personal_volume: number,
  ambassador_status: string
}

const NameColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.span`
  margin-left: 0px;
`;

const columns = {
  level: {
    key: "level",
    label: "Level",
    content: (item: UserInterface) => (
      <NameColumn>
        <Name>{item.level}</Name>
      </NameColumn>
    )
  },
  account_id: {
    key: "account_id",
    label: "Account ID",
    content: (item: UserInterface) => (
      <NameColumn>
        <Name>{item.account_id}</Name>
      </NameColumn>
    ),
    hideontablet: "true",
    hideonphone: "true"
  },
  name: {
    key: "name",
    label: "Name",
    content: (item: UserInterface) => (
      <NameColumn>
        <Name>{item.first_name + ' ' + item.last_name}</Name>
      </NameColumn>
    )
  },
  email: {
    key: "email",
    label: "Email",
    hideonphone: "true"
  },
  personal_volume: {
    key: "personal_volume",
    label: "Volume",
    align: "center",
    width: 100,
    content: (item: UserInterface) => (
      <NameColumn>
        <Name>{item.personal_volume + ' CV'}</Name>
      </NameColumn>
    )
  },
  ambassador_status: {
    key: "ambassador_status",
    label: "Status",
    align: "center"
  },
  detailLink: {
    key: "detailLink",
    align: "center"
  }
};

const rowConfig = {
  uniqueKey: "id",
  css: `
    height: 73px;
    &:hover {
      background-color: rgba(216, 216, 216, 0.2)};
    }
  `,
  onClick: (e: any, item: UserInterface) => {
    console.log("Row was clicked", item);
  }
};

const MyNetworkPage = () => {
  const [NetworkData, setNetworkData] = useState<any | null>([]);

  useEffect(() => {
      // Can implement a <Loading/>
      //  start loading
      if(NetworkData.length === 0) {
        getNetworkTableData().then(data => setNetworkData(data))
      }
      //  finish loading
  }, [NetworkData, setNetworkData])
  
  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">MY NETWORK</div>
        <div className="subtitle">All your network</div>
        <NetworkTable
          rowConfig={rowConfig}
          columns={columns}
          data={NetworkData}
        />
      </StyledDivPage>
    </StyledContainer>
  );
};

export default MyNetworkPage;