import { AxiosError } from 'axios';
import { axiosAuth, getHeaders } from '../../api/axios';

export function getNetworkTableData() {

  const networkData = getUserNetwork().then(data => adaptNetworkTableData(data))

  return networkData
}

export function getNetworkChartData() {

  const networkData = getUserNetwork().then(data => adaptNetworkChartData(data))

  console.log("networkData  x: ", networkData)

  return networkData
}

const getUserNetwork = async () => {

  //TODO
  //get real information about ambassador and network    
  //const user_id = localStorage.getItem("user_id");
  try {
      const TOTAL_SALES_VOLUME_URL = '/payments/37/total';

      const { data } = await axiosAuth.get(TOTAL_SALES_VOLUME_URL, {headers: getHeaders()})

      return data
      
  } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as any
      if(data?.error === "No network data found") {
          return []
      }
      else {
          console.error(error);
          return []
      }
      
  }
}

function adaptNetworkTableData (data: any) {
  return [
    {
      id: 1,
      level: 0,
      account_id: '000 000 022',
      first_name: "Name",
      last_name: "Surname",
      email: "user@rewards4earth.com",
      personal_volume: 126,
      ambassador_status: "Ambassador"
    },
    {
      id: 2,
      level: 1,
      account_id: '000 000 023',
      first_name: "Name 2",
      last_name: "Surname 2",
      email: "user@rewards4earth.com",
      personal_volume: 127,
      ambassador_status: "User"
    },
    {
      id: 3,
      level: 1,
      account_id: '000 000 024',
      first_name: "Name 3",
      last_name: "Surname 3",
      email: "user@rewards4earth.com",
      personal_volume: 128,
      ambassador_status: "User"
    },
    {
      id: 4,
      level: 1,
      account_id: '000 000 025',
      first_name: "Name 4",
      last_name: "Surname 4",
      email: "user@rewards4earth.com",
      personal_volume: 129,
      ambassador_status: "User"
    },
    {
      id: 5,
      level: 1,
      account_id: '000 000 026',
      first_name: "Name 5",
      last_name: "Surname 5",
      email: "user@rewards4earth.com",
      personal_volume: 130,
      ambassador_status: "User"
    },
    {
      id: 6,
      level: 1,
      account_id: '000 000 027',
      first_name: "Name 6",
      last_name: "Surname 6",
      email: "user@rewards4earth.com",
      personal_volume: 131,
      ambassador_status: "User"
    }
  ]
}

function adaptNetworkChartData (data: any) {
  console.log("Data adapt: ", data)
  return {
    "name": "Name Surname",
    "id": "1",
    "level": 0,
    "ambassador_status": "Ambassador",
    "account_id": "000 000 022",
    "personal_volume": "126 CV",
    "organizationChildRelationship": [
      {
        "id": "2",
        "level": 1,
        "name": "Name 2 Surname 2",
        "account_id": "000 000 023",
        "personal_volume": "127 CV",
        "ambassador_status": "User"
      },
      {
        "name": "Name 3 Surname 3",
        "account_id": "000 000 024",
        "id": "3",
        "level": 1,
        "personal_volume": "128 CV",
        "ambassador_status": "User",
        "organizationChildRelationship": [
          {
            "name": "Name 4 Surname 4",
            "id": "4",
            "level": 2,
            "account_id": "000 000 025",
            "personal_volume": "129 CV",
            "ambassador_status": "User"
          }
        ]
      }
    ]
  }
}