import './BusinessInformation.css';
import { Grid, Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";

function handleSendEmail() {
    const subject = "Subject";
    const body = "Body";
    window.open(`mailto: ?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`)
}

function handleCopyToClipboard(referral_code) {
    const text = "Text to send referral code: " + referral_code;
    navigator.clipboard.writeText(text);
}

const BusinessInformationPage = () => {
    const user_promocode = JSON.parse(localStorage.getItem("user_object"))?.ambassador_promocode;
    const [copySuccess, setCopySuccess] = useState('');

    return (
        <header id="businessheader" className="bg-image">
            <div className="bg-container">
                <h1>Business Development Manager</h1>
                <h2>Brief explaination about businesses and how to add a BDM to your downline</h2>
                <div style={{ margin: '10px' }}></div>
                <h3>Refer new members by email or whatsapp sharing your promocode: {user_promocode}</h3>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                handleCopyToClipboard(user_promocode);
                                setCopySuccess('Copied!');
                            }}
                            startIcon={<ContentCopyIcon />}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            COPY REFERRAL MESSAGE
                        </Button>
                        <div style={{color: 'green'}}>{copySuccess}</div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                handleSendEmail();
                            }}
                            startIcon={<SendIcon />}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            SEND EMAIL
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </header>
    )
}

export default BusinessInformationPage;