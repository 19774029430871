import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import RadioButtonsGroupComponent from '../../components/login/RadioButtonsGroupComponent';
import Grid from '@mui/material/Grid';
import { adaptPhoneNumber } from '../../api/UserService'
import { sendSmsCode } from '../signup/signUpService';
import validate from './RecoverPasswordValidate';
import { FormErrorMessage } from '../../components/common/FormStyles';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "1rem",
            top: "-1",
          }),
        }),
      },
    },
  },
});

const RecoverPassword = () => {
  const [userType, setUserType] = useState(null);
  const [error, setError] = useState("");
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onChangeUserType = (newValue)=>{
		setUserType(newValue);
	}

  useEffect(() => {
    setEnableSubmitButton( 
      userType !== null && 
      phoneNumber !== null && 
      phonePrefix !== null &&
      email !== null
    )
  }, [userType, phoneNumber, phonePrefix, email])

  useEffect(() => {
		setError("");
	}, [phoneNumber, phonePrefix, email]); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const resultError = validate(phonePrefix, phoneNumber, email);

		if (resultError !== null) {
			setError(resultError);
			return;
		}

    sendSmsCode( phonePrefix, adaptPhoneNumber(phoneNumber), email, userType );

    navigate(
      '/sms',
      { state:
        {
          flow_type: "recover_password",
          user_type: userType,
          user_phone_number: adaptPhoneNumber(phoneNumber),
          user_phone_prefix: phonePrefix,
          user_email: email
        }
      }
    );
}

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recover password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="prefix"
                  placeholder="Phone prefix"
                  name="prefix"
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => setPhonePrefix(e.target.value)}
                  value={phonePrefix}
                  autoFocus
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  placeholder="Phone number"
                  name="phone"
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email"
                  name="email"
                  type="email"
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  autoFocus
                />
              </Grid>
            </Grid>

            <div style={{ margin: '20px 0' }}></div>

            <RadioButtonsGroupComponent
              onChangeFunction={onChangeUserType}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!enableSubmitButton}
            >
              Send SMS
            </Button>

            {error !== "" && (
							<FormErrorMessage>
								{error}
							</FormErrorMessage>
						)}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
};

export default RecoverPassword;