import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import { Grid } from "@mui/material";
import ProfilePhoto from "./ProfilePhoto";
import ProfileTable from "./ProfileTable";
import Button from "@mui/material/Button";
import {Link} from 'react-router-dom';

const ProfilePage = () => {
  const user_object = JSON.parse(localStorage.getItem("user_object"));

  const user = {
    first_name: user_object?.ambassador_first_name,
    last_name: user_object?.ambassador_last_name,
    country: user_object?.ambassador_country,
    city: user_object?.ambassador_city,
    email: user_object?.ambassador_email,
    phone: user_object?.ambassador_phone_number
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">PROFILE</div>
        <div className="subtitle">Details</div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ProfilePhoto {...user} />
            <Link to="/changepassword">
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 2, mb: 2, width: { xs: "75%", md: "80%" }, height: { xs: "20%", md: "10%" } }}
              >
                Change password
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfileTable {...user}/>
          </Grid>
        </Grid>
      </StyledDivPage>
    </StyledContainer>
  );
};

export default ProfilePage;
