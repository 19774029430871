import React, { useState, useEffect } from 'react';
import {
	FormColumn,
	FormWrapper,
	FormInput,
	FormRow,
	FormLabel,
	FormInputRow,
	FormErrorMessage,
	FormTitle,
    Container,
	FormSelectDiv
} from '../../components/common/FormStyles';
import validateForm from '../signup/SignUpAmbassadorValidateForm';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AutocompleteAddressComponent } from '../../components/common/AutocompleAddressComponent';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

const Form = () => {
	const [newUserData, setUserData] = useState({
		business_name: '',
		abn: '',
		user_first_name: '',
		user_last_name: '',
		user_email: '',
		user_password: '',
		user_phone_number: '',
		user_phone_prefix: '',
		promoted_by_code: '',
		unit: '',
		address: {}
	});
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [error, setError] = useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();

	const handleInformationPopUp = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseInformationPopUp = () => {
		setAnchorEl(null);
	};

	const openPopUp = Boolean(anchorEl);
	const idPopUp = openPopUp ? 'simple-popover' : undefined;

	const onChangeUserAddress = (newValue)=>{
		setUserData((previous) => ({...previous, address: newValue}))
	}

	useEffect(() => {
		setError("");
	}, [newUserData]);  

	const handleSubmit = async (e) => {
		e.preventDefault();

		const resultError = validateForm(newUserData, passwordConfirmation);

		if (resultError !== null) {
			setError(resultError);
			return;
		}

		navigate(
			'/signuppayment',
			{ state:
				{
					user: newUserData
				}
			}
		);
	};

	const formData = [
		{ 
			label: 'First Name *', value: newUserData.user_first_name, 
			onChange: (e) => setUserData((previous) => ({...previous, user_first_name: e.target.value})), 
			type: 'text' 
		},
        { 
			label: 'Last Name *', value: newUserData.user_last_name, 
			onChange: (e) => setUserData((previous) => ({...previous, user_last_name: e.target.value})),
			type: 'text' 
		},
		{ 
			label: 'ABN Number *', value: newUserData.abn, 
			onChange: (e) => setUserData((previous) => ({...previous, abn: e.target.value})), 
			type: 'text' 
		},
		{ 
			label: 'Business Name *', value: newUserData.business_name, 
			onChange: (e) => setUserData((previous) => ({...previous, business_name: e.target.value})), 
			type: 'text' 
		},
		{ 
			label: 'Email *', value: newUserData.user_email, 
			onChange: (e) => setUserData((previous) => ({...previous, user_email: e.target.value})), 
			type: 'email' 
		},
		{ 
			label: 'Password', value: newUserData.user_password, 
			onChange: (e) => setUserData((previous) => ({...previous, user_password: e.target.value})), 
			type: 'password' 
		},
		{ 
			label: 'Confirm Password', value: passwordConfirmation, 
			onChange: (e) => setPasswordConfirmation( e.target.value ), 
			type: 'password' 
		},
		{ 
			label: 'Phone Number Country *', value: newUserData.user_phone_prefix, 
			onChange: (e) => setUserData((previous) => ({...previous, user_phone_prefix: e.target.value})), 
			type: 'select',
			options: [
				{
					datavalue: "AU",
					value: "+61",
					label: "Australia (+61)",
					key: "option-au"
				},
				{
					datavalue: "NZ",
					value: "+64",
					label: "New Zeland (+64)",
					key: "option-nz"
				},
				{
					datavalue: "SGP",
					value: "+65",
					label: "Singapore (+65)",
					key: "option-sgp"
				}
			]
		},
        { 
			label: 'Phone Number *', value: newUserData.user_phone_number, 
			onChange: (e) => setUserData((previous) => ({...previous, user_phone_number: e.target.value})), 
			type: 'text' 
		},
		{ 
			label: 'Unit (Optional)', value: newUserData.unit, 
			onChange: (e) => setUserData((previous) => ({...previous, unit: e.target.value})), 
			type: 'text' 
		},
		{ 
			label: 'Address (No PO BOX) *', value: newUserData.address, 
			onChange: (e) => setUserData((previous) => ({...previous, address: e.target.value})), 
			type: 'address' 
		},
		{ 
			label: 'BDM Referral Code (Optional)', value: newUserData.promoted_by_code, 
			onChange: (e) => setUserData((previous) => ({...previous, promoted_by_code: e.target.value})), 
			type: 'text',
			information_link: 'Details',
			information_text: 'If a BDM has referred you, it is important their referral number is entered here as can not be done at a later date.  Your BDM should have given this to you, if you do not have it, please check with them before proceeding.   If you have not been referred by a BDM, please leave blank.'
		},
	];
	return (
			<Container>
				<FormRow>
					<FormColumn>
						<FormTitle>Create new member</FormTitle>
						<FormWrapper onSubmit={handleSubmit}>
							{formData.map((el, index) => (
								<FormInputRow key={index}>
									<FormLabel class={el.class}>
                                        {el.label}
                                    </FormLabel>
									{
										(el.type !== "select" && el.type !== "address" && !el.information_link) && 
										(
											<FormInput
												type={el.type}
												placeholder={`Enter ${el.label}`}
												value={el.value}
												onChange={el.onChange}
											/>
										)
									}
									{
										(el.information_link) &&
										(
											<Stack direction="row" spacing={1}>
												<FormInput
													type={el.type}
													placeholder={`Enter ${el.label}`}
													value={el.value}
													onChange={el.onChange}
												/>
												<IconButton color="primary" aria-label="information" onClick={handleInformationPopUp}>
													<InfoIcon />
												</IconButton>
												<Popover
													id={idPopUp}
													open={openPopUp}
													anchorEl={anchorEl}
													onClose={handleCloseInformationPopUp}
													anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
													}}
												>
													<Typography variant="caption">{el.information_text}</Typography>
												</Popover>
											</Stack>
										)
									}
									{
										el.type === "select" && 
										(
											<FormSelectDiv>
												<Select sx={{ m: 1, minWidth: 250, maxHeight: 25 }}
													//labelId="demo-simple-select-label"
													//id="demo-simple-select"
													//value={age}
													//label="Age"
													onChange={el.onChange}
													defaultValue=""
												>
													{
														el.options.map((option, index) => (
															<MenuItem value={option.value} key={option.key}>{option.label}</MenuItem>
														))
													} 
												</Select>
											</FormSelectDiv>
										)
									}
									{
										el.type === "address" && 
										(
											<AutocompleteAddressComponent
												onChangeUserAddress={onChangeUserAddress}
											/>
										)
									}
								</FormInputRow>							
							))}
							<Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Pay to register
                            </Button>
						</FormWrapper>
						{error ? (
							<FormErrorMessage>
								{error}
							</FormErrorMessage>
						) : null}
					</FormColumn>
				</FormRow>
			</Container>
	);
};

export default Form;