import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { getUser } from '../../api/UserService'
import * as salesService from '../../api/SalesServices';

function createData(
  description: string,
  value: any
) {
  const stringValue = value ? value.toString() : '(not set)' 
  return { description, stringValue };
}

function createRows(
    userData: any,
    CPVolume: any 
  ) {
    return [
        createData( 'Full name', userData?.ambassador_first_name + ' ' + userData?.ambassador_last_name ),
        createData( 'E-mail', userData?.ambassador_email ),
        createData( 'Country', userData?.ambassador_country ),
        createData( 'Region', userData?.ambassador_city ),
        createData( 'Sales volume', CPVolume?.total + ' CV' ),
        createData( 'Ambassador status', 'Ambassador' ),
    ];
}

const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
};

type Props = {
    user_id: number
}

export default function ReferralTable(props: Props) {
    const [User, setUser] = useState<any | null>(null);
    const [CPVolume, setCPVolume] = useState<any | null>(null);
    const { user_id } = props
    const prevState = usePrevious({user_id});
  
    useEffect(() => {
        // Can implement a <Loading/>
        //  start loading
        getUser(user_id).then(data => setUser(data))

        if( !CPVolume || prevState?.user_id !== user_id ) {
            salesService.getCurrentPeriodSalesVolume(user_id).then(data => setCPVolume(data))
        }

        //  finish loading
    }, [CPVolume, setCPVolume, user_id, prevState]);

    const rows = createRows(User, CPVolume);

    return (
        <TableContainer component={Paper} sx={{ maxWidth: { md: "450px" } }} >
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell component="th" align="center" colSpan={2}>
                        <Typography fontWeight={600} variant='h6' sx={{ textAlign: { xs: "left", md: "center" } }}>
                            Selected referral information
                        </Typography>
                    </TableCell>
                </TableRow> 
                {rows.map((row) => (
                <TableRow
                    key={row.description}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" sx={{ width: { xs: "30%", md: "45%" } }}>
                        <Typography fontWeight={600}>
                            {row.description}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ align: { xs: "left", md: "right" } }}>
                        {row.stringValue}
                    </TableCell>
                </TableRow>
                ))}
            </TableHead>
        </Table>
        </TableContainer>
    );
}