import { useRef, useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { updateUserPassword } from '../../api/UserService';
import validatePassword from './NewPasswordValidate';
import { useNavigate, useLocation } from "react-router-dom";

const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "13px",
            top: 2,
          },
        },
        styleOverrides: {
          shrink: ({ ownerState, theme }) => ({
            ...(ownerState.shrink && {
              fontSize: "1rem",
              top: "-1",
            }),
          }),
        },
      },
    },
  });

export default function NewPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();

    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [userUpdateData, setUserUpdateData] = useState('');
    const [success, setSuccess] = useState(false);
    const userType = location?.state?.user_type;

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        if (success === true) {
            navigate('/login');
        }
    }, [success, navigate]);

    useEffect(() => {
        setErrMsg('');
    }, [newPwd, confirmPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const resultError = validatePassword(newPwd, confirmPwd);

		if (resultError !== null) {
			setErrMsg(resultError);
			return;
		}

        await updateUserPassword(newPwd, userType).then(data => setUserUpdateData(data));
        
    }

    useEffect(() => {
        if(userUpdateData) {
            if( !userUpdateData?.user_id && !userUpdateData?.charity_id ) {
                console.log("userUpdateData: ", userUpdateData)
                setErrMsg( "Error updating new password" );
            } else {
                setSuccess( true );
            }
        }
    }, [userUpdateData])

  return (
    <>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newpassword"
              placeholder="New Password"
              type="password"
              id="newPwd"
              onChange={(e) => setNewPwd(e.target.value)}
              value={newPwd}
              InputLabelProps={{shrink: false}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              placeholder="Confirm Password"
              type="password"
              id="confirmPwd"
              onChange={(e) => setConfirmPwd(e.target.value)}
              value={confirmPwd}
              InputLabelProps={{shrink: false}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </>
  );
}