import { getWithExpiry } from '../context/MyLocalStorage';
import Login from '../pages/login/Login';

export const PrivateRoute = ({ children }) => {
    const token = getWithExpiry("token");

    if (!token) {
      // user is not authenticated
      return <Login />;
    }
    
    return children;
};
