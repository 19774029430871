import { SalesHeader } from './styles';

interface IProps {
    title: string,
    bgHeaderColor: string
}

export default function SalesComponentHeader( props: IProps ) {
    return (
        <SalesHeader $inputColor={props.bgHeaderColor}>
            <div>{props.title}</div>
        </SalesHeader>
    )
};