import { axiosAuth, getHeaders } from "../../api/axios";
import { AxiosError } from "axios";

export function getPayoutHistoryData() {
  const transactionsData = getUserPayoutData().then((data) =>
    adaptPayoutTransactionsTableData(data)
  );

  return transactionsData;
}

export function getPendingTransactionsData() {
  const transactionsData = getUserPayoutData().then((data) =>
    adaptPendingTransactionsTableData(data)
  );

  return transactionsData;
}

const getUserPayoutData = async () => {  
  try {
    //TODO
    //get real information about ambassador and network
    const user_id = localStorage.getItem("user_id");
    //const user_id = 37;
    const TRANSACTIONS_URL = "payments?user_id=37" //+ ambassador_id?.toString();

    const { data } = await axiosAuth.get(TRANSACTIONS_URL, {
        headers: getHeaders(),
      });

    return data;
  } catch (err) {
    const error = err as AxiosError;
    const data = error.response?.data as any;
    if (data?.error === "No transactions found") {
      return [];
    } else {
      console.error(error);
      return [];
    }
  }
};

export const postNewPayout = async (transactionsList: Array<number>) => {  
  try {
    //TODO
    //change it to current user
    const user_id = localStorage.getItem("user_id");

    const PAYOUT_URL = "payout?user_id=37" //+ user_id?.toString();

    const { data } = await axiosAuth.post(
      PAYOUT_URL, 
      {
        'payments': transactionsList
      },
      {
        headers: getHeaders(),
      });

    console.log("Data postNewPayout:", data);

    return data;
  } catch (err) {
    console.log("Error postNewPayout:", err);
    const error = err as AxiosError;
    const data = { "errMessage": error.message }
    return data;
  }
};

function adaptPayoutTransactionsTableData(data: Array<any>) {
  var all = [];
  if (data.length > 0) {
    for (var i = 0; i < data.length; i++) {
      var cur = data[i];
      all.push({
        request_id: cur.payment_id,
        request_date: cur.datetime,
        amount: cur.erths_amount,
        payment_system: cur.origin,
        status: cur.status,
      });
    }
  } else {
    all = [
      {
        request_id: "No transactions",
        request_date: "",
        amount: "",
        payment_system: "",
        status: "",
      },
    ];
  }

  return all.sort((a, b) => (a.request_id > b.request_id ? -1 : 1));
}

function adaptPendingTransactionsTableData(data: Array<any>) {
  var all = [];
  if (data.length > 0) {
    for (var i = 0; i < data.length; i++) {
      var cur = data[i];
      all.push({
        payment_id: cur.payment_id,
        date: cur.datetime,
        amount: cur.erths_amount,
        payment_system: cur.origin,
        selected: false,
      });
    }
  } else {
    all = [
      {
        payment_id: "No transactions",
        date: "",
        amount: "",
        payment_system: "",
        selected: false,
      },
    ];
  }

  return all.sort((a, b) => (a.payment_id > b.payment_id ? -1 : 1));
}