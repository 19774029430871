import styled, { css } from "styled-components";
import { rgba } from "polished";

const sizes = {
    giant: 1440,
    desktop: 1024,
    tablet: 768,
    phone: 420
  };

const screenSmallerThan = Object.keys(sizes).reduce(
    (accumulator, label) => {
      const emSize = sizes[label] / 16;
      accumulator[label] = (...args: any) => css`
        @media (max-width: ${emSize}em) {
          ${css(...args)};
        }
      `;
      return accumulator;
    },
    {}
  );

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x:auto;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    color: #686f7a;

    tbody:before {
    content: "-";
    display: block;
    line-height: 0.6em;
    color: transparent;
    }
`;

export const TableHeader = styled.thead`
    position: sticky;
    z-index: 100;
`;

export const BodyTableRow = styled.tr`
    background: ${({ theme }) => theme.bg};
`;

export const TableHeaderCell = styled.th`
    text-align: ${props => (props.align ? props.align : "left")};
    font-size: 18px;
    color: #686f7a;
    font-weight: 700;
    opacity: 0.8;
    ${screenSmallerThan.desktop`
        display: ${props => props.hideondesktop && "none"};
    `};
    ${screenSmallerThan.tablet`
        display: ${props => props.hideontablet && "none"};
        font-size: 16px;
    `};
    ${screenSmallerThan.phone`
        display: ${props => props.hideonphone && "none"};
        font-size: 15px;
    `};
    ${props => props.css && css(...props.css)};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
    border-bottom: 2px solid ${rgba(150, 150, 150, 0.5)};
    ${props => props.css && css(...props.css)};
`;

export const TableCell = styled.td`
    text-align: ${props => (props.align ? props.align : "left")};
    font-size: 17px;
    ${screenSmallerThan.desktop`
        display: ${props => props.hideondesktop && "none"};
    `};
    ${screenSmallerThan.tablet`
        display: ${props => props.hideontablet && "none"};
        font-size: 15px;
    `};
    ${screenSmallerThan.phone`
        display: ${props => props.hideonphone && "none"};
        font-size: 14px;
    `};
    ${props => props.css && css(...props.css)};
`;