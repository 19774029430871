import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "1rem",
            top: "-1",
          }),
        }),
      },
    },
  },
});

const SignUpFailure = () => {
  const location = useLocation();
  const error = location?.state?.error;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ErrorOutlineOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                There is an error creating new BDM, please contact our support team.
            </Typography>
            <div style={{ margin: '20px 0' }}></div>
            <Typography component="h1" variant="h5">
                Error: {error}
            </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  )
};

export default SignUpFailure;