import styled from "styled-components";

export const CardHeader = styled.div`
  background: #000000;
  height: 35px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 50px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 0.25em 1em;
  font-weight: 400;
`;

export const CardSection = styled.div`
  background: #FFFFFF;
  height: 35px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 50px;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 0.25em 1em;
  font-weight: 400;
  border-bottom: solid;
  border-bottom-width: 1px;
`;

export const CardBody = styled.div<{ $inputColor?: string }>`
  background: ${props => props.$inputColor || "#3c6cdd"};
  height: 70px;
  justify-content: center;
  vertical-align: middle;
  line-height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.8em;
  padding: 0.25em 1em;
  font-weight: 500;
`;
export const CardButtonSection = styled.div`
  background: #FFFFFF;
  height: 60px;
  justify-content: center;
  vertical-align: middle;
  line-height: 30px;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 1.8em;
  padding: 0.25em 1em;
  font-weight: 500;
`;