import React, { useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Redirect = ( props ) => {
    useEffect(() => {
        window.open(
            props.url,
            '_blank',
            "noreferrer"
        );
    }, [props]);

   return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ArrowForwardOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                Redirecting...
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={() => { window.location.reload() }}
                    sx={{ mt: 3, mb: 2 }}
                >
                    Open CRM
                </Button>
            </Box>
        </Box>
    </Container>
   );
};

export default Redirect;