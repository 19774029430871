import React from "react";
import { useState, useEffect } from 'react';
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getNetworkChartData } from '../services'
import TreeCard from './TreeCardComponent'

function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o?.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    if(o?.collapsed) {
        o.collapsed = collapsed;
    }
  });
  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );
  return collapsed ? (
    <T
      label={
        <TreeCard
            org={ o }
            onCollapse={ handleCollapse }
            collapsed={ collapsed }
        />
      }
    />
  ) : (
    <T
      label={
        <TreeCard
            org={ o }
            onCollapse={ handleCollapse }
            collapsed={ collapsed }
        />
      }
    >
      {_.map(o?.organizationChildRelationship, (c) => (
        <Node o={c} parent={o} key={c.id} />
      ))}
    </T>
  );
}

export default function TreeGraph(props) {
  const [NetworkData, setNetworkData] = useState(null);

  useEffect(() => {
      // Can implement a <Loading/>
      //  start loading
      if(!NetworkData) {
        getNetworkChartData().then(data => setNetworkData(data))
      }
      console.log("NetworkData: ", NetworkData)
      //  finish loading
  }, [NetworkData, setNetworkData])

  return (
    <DndProvider backend={HTML5Backend}>
        <Node o={NetworkData} />
    </DndProvider>
  );
}
