export default function validatePassword( password, passwordConfirmation ) {

	if (!password) {
		return 'Password required';
	} else if (password.length < 8) {
        return("Password must have minimum 8 characters");
    } else if (password.length > 50) {
        return("Password must have maximum 50 characters");
    } else if (password.search(/\d/) === -1) {
        return("Password must have a number");
    } else if (password.search(/[a-zA-Z]/) === -1) {
        return("Password must have a letter");
    } else if (password.search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
        return("Invalid password character");
    }

	if(password !== passwordConfirmation) {
		return 'Password confirmation must match';
	}

	return null;
}