import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import { getWithExpiry } from './context/MyLocalStorage';
import SmsForm from "./pages/signup/SmsForm";
import RecoverPassword from "./pages/login/RecoverPassword";
import NewPassword from "./pages/login/NewPassword";
import SignUpPayment from "./pages/signup/SignUpPayment";
import PaymentSuccess from "./pages/signup/PaymentSuccess";
import SignUpFailure from "./pages/signup/SignUpFailure";
import PaymentFailure from "./pages/signup/PaymentFailure";

function App() {
  const token = getWithExpiry("token");
  const user_email = localStorage.getItem("user_email");
  const user_object = localStorage.getItem("user_object");

  return (
    <>
    {(token && user_email && user_object) ? ( 
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    ) : (
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/:promoCode" element={<SignUp />}/>
        <Route path="/sms" element={<SmsForm />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recoverpassword" element={<RecoverPassword />} />
        <Route path="/signuppayment" element={<SignUpPayment />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/signupfailure" element={<SignUpFailure />} />
        <Route path="/paymentfailure" element={<PaymentFailure />} />
      </Routes>
    )}
    </>
  );
}

export default App;
