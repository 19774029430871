import { CardHeader } from './styles';

interface IProps {
    title: string,
    bgColor: string
}

export default function CardComponentHeader( props: IProps ) {
    return (
        <CardHeader $inputColor={props.bgColor}>
            <div>{props.title}</div>
        </CardHeader>
    )
};