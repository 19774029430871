import React from 'react'
import Form from './NewMemberForm';
import { StyledContainer, StyledDivPage } from "../../components/common/StyledSection";

const RegisterNewMemberpage = () => {
  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">REGISTER NEW MEMBER</div>
        <div className="subtitle">Sign up new frontline member</div>
        <Form/>
      </StyledDivPage>
    </StyledContainer>
  )
};

export default RegisterNewMemberpage;