import { CardHeader } from './styles';

interface IProps {
    title: string
}

export default function CardComponentHeader( props: IProps ) {
    return (
        <CardHeader>
            <div>{props.title}</div>
        </CardHeader>
    )
};