import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

function createData(description: string, value: any) {
  const stringValue = value ? value.toString() : "(not set)";
  return { description, stringValue };
}

function createRows(userData: ICharity) {
  return [
    createData("Name", userData?.name),
    createData("E-mail", userData?.email),
    createData("Phone", userData?.phone),
    createData("Address", userData?.address),
    createData("Region", userData?.city),
    createData("State", userData?.state),
    createData("Postal code", userData?.postal_code),
    createData("Country", userData?.country),
    createData("First name contact", userData?.first_name_contact),
    createData("Last name contact", userData?.last_name_contact),
    createData("Members quantity", userData?.members_quantity),
  ];
}

interface ICharity {
  name: string;
  first_name_contact: string;
  last_name_contact: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  email: string;
  phone: string;
  members_quantity: string;
}

export default function ProfileTable(charity: ICharity) {
  const rows = createRows(charity);

  return (
    <TableContainer component={Paper} sx={{ width: { xs: "290px"}}}>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.description}
              sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
            >
              <TableCell component="th" scope="row">
                <Typography fontWeight={600}>{row.description}</Typography>
              </TableCell>
              <TableCell align="right">{row.stringValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
