import { useRef, useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from "../../App";
import { login } from '../../api/UserService'
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import RadioButtonsGroupComponent from '../../components/login/RadioButtonsGroupComponent';
import { getWithExpiry } from '../../context/MyLocalStorage';

const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "13px",
            top: 2,
          },
        },
        styleOverrides: {
          shrink: ({ ownerState, theme }) => ({
            ...(ownerState.shrink && {
              fontSize: "1rem",
              top: "-1",
            }),
          }),
        },
      },
    },
  });

export default function SignIn() {
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [enableSubmitButton, setEnableSubmitButton] = useState(false);

    const [userType, setUserType] = useState(null);
    const accessToken = getWithExpiry("token");

    const onChangeUserType = (newValue)=>{
      setUserType(newValue);
    }

    useEffect(() => {
      setEnableSubmitButton(user.length !== 0 & pwd.length !== 0 & userType !== null)
    }, [user,pwd,userType])

    useEffect(() => {
        if(accessToken) {
          if(userType === "ambassador")
            navigate('/profile');
          else
            navigate('/charityprofile');
        }
    }, [accessToken, navigate, userType])

    useEffect(() => {
      userRef.current?.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { data } = await login(user, pwd, userType).then(data => data);

        if( !data || data?.errorMessage ) {
            setErrMsg(data?.errorMessage);
            errRef.current.focus();
        } else {
            setSuccess(true);
        }
    }

    const handleSignUp = async () => {
      navigate(
        '/signup',
        { state:
          {
            isFree: false
          }
        }
      );
    }  

    const handleFreeSignUp = async () => {
      navigate(
        '/signup',
        { state:
          {
            isFree: true
          }
        }
      );
    }  

    const handleRecoverPassword = async () => {
      navigate('/recoverpassword');
    }  

  return (
    <>
    {success ? (
        <App />
    ) : (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <div style={{ margin: '20px 0' }}></div>
          <RadioButtonsGroupComponent
              onChangeFunction={onChangeUserType}
          />
          {userType !== null && (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Email Address"
              name="email"
              InputLabelProps={{shrink: true}}
              autoComplete="email"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="pwd"
              autoComplete="current-password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              InputLabelProps={{shrink: false}}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  Free user
                </Typography>
                <Link 
                onClick={handleFreeSignUp}
                >
                  Sign up for free
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  New user?
                </Typography>
                <Link 
                onClick={handleSignUp}
                >
                  Sign up
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!enableSubmitButton}
            >
              Sign In
            </Button>
            <Link 
                onClick={handleRecoverPassword}
                >
                  Forgotten password?
            </Link>
          </Box>)}
        </Box>
      </Container>
    </ThemeProvider>
    )}
    </>
  );
}