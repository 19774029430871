import styled from "styled-components";

export const StyledContainer = styled.section`
  max-width: 1024px;
  padding: 0 0px;
  margin: 0 auto;
`;

export const AvatarDiv = styled.section`
  flex-basis: 300px; 
  flex-shrink: 3;
`;

export const StyledDivPage = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

> .title {
  font-size: 20px;
  color: black;
  transition: 0.2s;
  padding-left: 5px;

  &:hover {
    color: grey;
  }

  + .dob {
    color: grey;
  }
}

.subtitle {
  font-size: 15px;
  color: #A7A7A6;
  transition: 0.2s;
  padding-left: 5px;
  padding-bottom: 25px;
`;