import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import Card from "../dashboard/InformationCardComponent";
import { getUserWalletBalance } from "../../api/UserService";
import { useState, useEffect } from "react";
import StyledTable from "../../components/common/StyledTable";
import { NameColumn, Name, TableHeader } from "../wallet/styles";
import { getPayoutHistoryData } from "./PayoutService";
import Container from "@mui/material/Container";

interface PayoutRequestHistory {
  id: number;
  request_id: string;
  request_date: string;
  amount: string;
  payment_system: string;
  status: string;
}

const columns = {
  request_id: {
    key: "request_id",
    label: "Request ID",
    content: (item: PayoutRequestHistory) => (
      <NameColumn>
        <Name>{item.request_id}</Name>
      </NameColumn>
    ),
  },
  request_date: {
    key: "request_date",
    label: "Request date",
    content: (item: PayoutRequestHistory) => (
      <NameColumn>
        <Name>{item.request_date}</Name>
      </NameColumn>
    ),
  },
  amount: {
    key: "amount",
    label: "Amount",
    content: (item: PayoutRequestHistory) => (
      <NameColumn>
        <Name>{item.amount}</Name>
      </NameColumn>
    ),
  },
  payment_system: {
    key: "payment_system",
    label: "Payment system",
    content: (item: PayoutRequestHistory) => (
      <NameColumn>
        <Name>{item.payment_system}</Name>
      </NameColumn>
    ),
  },
  status: {
    key: "status",
    label: "Status",
    content: (item: PayoutRequestHistory) => (
      <NameColumn>
        <Name>{item.status}</Name>
      </NameColumn>
    ),
  },
};

const rowConfig = {
  uniqueKey: "id",
  css: `
    height: 73px;
    &:hover {
      background-color: rgba(216, 216, 216, 0.2)};
    }
  `,
  onClick: (e: any, item: PayoutRequestHistory) => {
    console.log("Row was clicked", item);
  },
};

const PayoutListPage = () => {
  const [WalletValue, setWalletValue] = useState<any | null>(null);
  const [PayoutData, setPayoutData] = useState<any | null>([]);

  useEffect(() => {
    // Can implement a <Loading/>
    //  start loading
    if (!WalletValue) {
      getUserWalletBalance().then((data) => setWalletValue(data.balance));
    }
    if (PayoutData.length === 0) {
      getPayoutHistoryData().then((data) => setPayoutData(data));
    }
    //  finish loading
  }, [WalletValue, setWalletValue, PayoutData, setPayoutData]);

  const virtualWalletProps = {
    headerTitle: "VIRTUAL WALLET",
    bodyValue: WalletValue,
    bodyIcon: "AttachMoney",
    bgHeaderColor: "#41B5DE",
    bgBodyColor: "#2B9BC2",
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">PAYOUT</div>
        <div className="subtitle">Manage payout requests</div>
        <Container sx={{ width: { xs: "100%", md: "30%" }, marginBottom: "20px", marginLeft: "0%" }}>
          <Card {...virtualWalletProps} />
        </Container>
        <TableHeader $inputColor={"#17D8B2"}>
          {"PAYOUT REQUEST HISTORY"}
        </TableHeader>
        <StyledTable
          rowConfig={rowConfig}
          columns={columns}
          data={PayoutData}
        />
      </StyledDivPage>
    </StyledContainer>
  );
};

export default PayoutListPage;
