import Header from './SalesComponentHeader';
import SalesTable from './SalesComponentTable';

interface IProps {
    title: string,
    volume: string,
    volumeName: string,
    bgHeaderColor: string
}

export default function SalesComponent(props: IProps) {
    return (
        <>
            <Header 
                title= {props.title}
                bgHeaderColor={props.bgHeaderColor}
            />
            <SalesTable 
                volume= {props.volume}
                volumeName= {props.volumeName}
            />
        </>
    );
};