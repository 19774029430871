import { axiosAuth, getHeaders } from "../../api/axios";

export const verifySmsCode = async (phone_prefix: string, phone_number: string, pin: string, user_type: string, user_email: string) => {
  let VERIFY_SMS_CODE_URL = "";
  
  if(user_type === "ambassador") {
    VERIFY_SMS_CODE_URL = "/sms/" + phone_prefix + "-" + phone_number + "/verify/" + pin + "/user";
  } else {
    VERIFY_SMS_CODE_URL = "/sms/" + phone_prefix + "-" + phone_number + "/verify/" + pin + "/charity/" + user_email;
  }

  console.log("VERIFY_SMS_CODE_URL: ", VERIFY_SMS_CODE_URL);

  try {
    const response = await axiosAuth.get(
        VERIFY_SMS_CODE_URL,
        { headers: getHeaders() }
    );
    console.log("verifySmsCode response: ", response);
    //let response = { data: { errorMessage: "Verification failed, try again" } };
    return response;
  } catch (err) {
    console.log("verifySmsCode err: ", err);
    let response = { data: { errorMessage: "Verification failed, try again" } };

    return response;
  }
};

export const sendSmsCode = async ( phone_prefix: string, phone_number: string, user_email: string, user_type: string ) => {
    const SEND_SMS_CODE_URL = "/sms"
    let json_body = {}

    if(user_type === "ambassador") {
      json_body = {
        "to" : phone_number,
        "type" : "user",
        "prefix" : phone_prefix
      }
    } else {
      json_body = {
        "to" : phone_number,
        "type" : "charity",
        "prefix" : phone_prefix,
        "email" : user_email
      }
    }

    console.log("/sms request body: ", json_body)

    try {
      const response = await axiosAuth.post(
            SEND_SMS_CODE_URL,
            json_body,
            {
                headers: { "Content-Type": "application/json" }
            }
      );
      console.log("sendSmsCode response: ", response);
      //let response = { data: { errorMessage: "Verification failed, try again" } };
      return response;
    } catch (err) {
      console.log("sendSmsCode err: ", err);
      let response = { data: { errorMessage: "Verification message hasn't been sent" } };
  
      return response;
    }
};
