import { axiosAuth, getHeaders } from "../../api/axios";

export function getReferralsData() {
  const transactionsData = getReferralsDataFromAPI().then((data) =>
    adaptPayoutTransactionsTableData(data)
  );

  return transactionsData;
}

const getReferralsDataFromAPI = async () => {  
  try {
    const user_object = JSON.parse(localStorage.getItem("user_object"));

    const REFERRALS_URL = "ambassadors?promoted_by_code=" + user_object?.ambassador_promocode?.toString();

    const { data } = await axiosAuth.get(REFERRALS_URL, {
        headers: getHeaders(),
      });

    return data;
  } catch (err) {
    const error = err;
    const data = error.response?.data;
    if (data?.error === "No transactions found") {
      return [];
    } else {
      console.error(error);
      return [];
    }
  }
};

function adaptPayoutTransactionsTableData( data ) {
  var all = [];
  if (data.length > 0) {
    for (var i = 0; i < data.length; i++) {
      var cur = data[i];
      all.push({
        id: cur.ambassador_id,
        full_name: cur.ambassador_first_name + ' ' + cur.ambassador_last_name,
      });
    }
  } else {
    all = [
      {
        request_id: "No transactions",
        request_date: "",
        amount: "",
        payment_system: "",
        status: "",
      },
    ];
  }

  return all;
}
