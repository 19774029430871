export default function validate( phonePrefix, phoneNumber, email ) {

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!email) {
		return 'Email required';
	} else if (emailRegex.test(email.toLocalLowerCase)) {
		return 'Email address is invalid';
	}

    var phoneRegex = /^0+\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
	if (!phoneNumber) {
		return 'Phone number is required';
	} else if (phoneRegex.test(phoneNumber)) {
		return 'Phone number must have 9 digits without area code';
	}

	if (!phonePrefix) {
		return 'Phone number prefix is required';
	} else if (phonePrefix !== '+61' && phonePrefix !== '+64' && phonePrefix !== '+65') {
		return 'Phone number prefix must be +61 (AU) +64 (NZ) or +65 (SG)';
	}

	return null;
}