import { useRef, useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { updateUserPassword, login } from '../../api/UserService';
import { useNavigate } from "react-router-dom";
import validatePassword from '../login/NewPasswordValidate'

const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontSize: "13px",
            top: 2,
          },
        },
        styleOverrides: {
          shrink: ({ ownerState, theme }) => ({
            ...(ownerState.shrink && {
              fontSize: "1rem",
              top: "-1",
            }),
          }),
        },
      },
    },
  });

export default function ChangePassword() {
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [oldPwd, setOldPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const user_type = localStorage.getItem("user_type");

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        if (success === true) {
          if(user_type === "charity") {
            navigate('/charityprofile');
          } else {
            navigate('/profile');
          }
        }
    }, [success, navigate, user_type]);

    useEffect(() => {
        setErrMsg('');
    }, [user, newPwd, oldPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const resultError = validatePassword(newPwd, newPwd);

        if (resultError !== null) {
          setErrMsg(resultError);
          return;
        }

        const user_type = localStorage.getItem("user_type");

        const { data } = await login(user, oldPwd, user_type).then(data => data);

        if( !data || data?.errorMessage ) {
            setErrMsg(data?.errorMessage);
            errRef.current.focus();
        } else {
            const userUpdateData = await updateUserPassword(newPwd, user_type).then(userUpdateData => userUpdateData);
            
            if( !userUpdateData?.ambassador_id ) {
                setErrMsg( "Error updating new password" );
            } else {
                //Updating token with new password
                const { data } = await login(user, newPwd, user_type).then(data => data);
                if( !data || data?.errorMessage ) {
                    setErrMsg(data?.errorMessage);
                    errRef.current.focus();
                } else {
                    setSuccess( true );
                }
            }
        }
    }

  return (
    <>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Email Address"
              name="email"
              InputLabelProps={{shrink: true}}
              autoComplete="email"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="currentpassword"
              placeholder="Current Password"
              type="password"
              id="oldPwd"
              autoComplete="old-password"
              onChange={(e) => setOldPwd(e.target.value)}
              value={oldPwd}
              InputLabelProps={{shrink: false}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newpassword"
              placeholder="New Password"
              type="password"
              id="newPwd"
              autoComplete="new-password"
              onChange={(e) => setNewPwd(e.target.value)}
              value={newPwd}
              InputLabelProps={{shrink: false}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </>
  );
}