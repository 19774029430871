import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AuthCode from 'react-auth-code-input';
import './styles.css';
import Button from '@mui/material/Button';
import { sendSmsCode, verifySmsCode } from './signUpService';
import { useNavigate } from "react-router-dom";
import { FormErrorMessage } from '../../components/common/FormStyles';
import Link from '@mui/material/Link';
import { setWithExpiry } from "../../context/MyLocalStorage";

const SmsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const flowType = location?.state?.flow_type;
  const userType = location?.state?.user_type;
  const userPhoneNumber = location?.state?.user_phone_number;
  const userPhonePrefix = location?.state?.user_phone_prefix;
  const userEmail = location?.state?.user_email;
  const [pin, setPin] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);

  function sendSMS() {

    console.log( "Sending verification message..");

    const { data } = sendSmsCode( userPhonePrefix, userPhoneNumber, userEmail, userType ).then(data => data);

    console.log( "Data: ", data );

    if( !data || data?.errorMessage ) {
      setError( data?.errorMessage );
    }

  };

  useEffect(() => {
		if ( success ) {
      if( flowType === "sign_up")
			  navigate( '/login' );
      else
        navigate( 
          '/newpassword',
          { state:
            {
              user_type: userType,
              user_phone_number: userPhoneNumber,
              user_phone_prefix: userPhonePrefix,
            }
          }
        )  
		}
	  }, [success, navigate, flowType, userPhoneNumber, userPhonePrefix, userType]);

	useEffect(() => {
		setError("");
	}, [pin]);  

	useEffect(() => {
		if( data ) {
			if( data.errorMessage ) {
				setError(data.errorMessage);
			} else {
        console.log("Data after verifying phone number: ", data);
        setWithExpiry("token", data?.token, 30);
        localStorage.setItem("user_type", userType);
        if(userType === "ambassador") {
          localStorage.setItem("user_id", data?.user_id);
        } else {
          localStorage.setItem("user_id", data?.charity_id);
        }
				setError("");
				setSuccess(true);
			}
			console.log("Data changed: ", data);
    }
	}, [data, userType]); 

  const handleOnChange = (res: string) => {
    setPin(res);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    verifySmsCode(userPhonePrefix, userPhoneNumber, pin, userType, userEmail).then(response => setData(response.data));
  };

  const handleSendSMS = (e) => {
    e.preventDefault();

    setError("");
    sendSMS();

  };

  return (
    <div className='main'>
      <div>
        <h1>
          Confirm your
          <br /> phone number
        </h1>
        <AuthCode
          key={"numeric"}
          allowedCharacters="numeric"
          onChange={handleOnChange}
          containerClassName='container'
          inputClassName='input'
          isPassword={false}
          disabled={false}
        />
        <p>
          A message with a verification code has been sent to <br />
          your mobile phone. Enter the code to continue.
        </p>
        <Button
            type="submit"
            variant="contained"
            onClick={(e) => handleConfirm(e)}
          >
            Confirm
        </Button>
        {error !== "" && (
            <>
							<FormErrorMessage>
								{error}
							</FormErrorMessage>
              <Link 
                onClick={(e) => handleSendSMS(e)}
              >
                Send new SMS
              </Link>
            </>  
        )}
      </div>
    </div>
  );
};

export default SmsForm;