export default function validateForm({ 
	user_first_name, 
	user_last_name, 
	user_email, 
	user_phone_number, 
	user_phone_prefix, 
	user_password, 
	abn, 
	business_name, 
	address 
}, 
	passwordConfirmation) {

	if (!user_first_name.trim()) {
		return 'First name required';
	}

	if (!user_last_name.trim()) {
		return 'Last name required';
	}

	if (!abn.trim()) {
		return 'ABN number required';
	} 
	else if( !ABNValidation( abn ) ){
        return 'ABN must be valid';
    }

	if (!business_name.trim()) {
		return 'Business name required';
	}

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!user_email) {
		return 'Email required';
	} else if (emailRegex.test(user_email.toLocalLowerCase)) {
		return 'Email address is invalid';
	}

	function isEmpty(obj) {
		for (const prop in obj) {
		  if (Object.hasOwn(obj, prop)) {
			return false;
		  }
		}
	  
		return true;
	}

	if( isEmpty( address ) ) {
		return 'Address is required';
	}

	if (!user_password) {
		return 'Password required';
	} else if (user_password.length < 8) {
        return("Password must have minimum 8 characters");
    } else if (user_password.length > 50) {
        return("Password must have maximum 50 characters");
    } else if (user_password.search(/\d/) === -1) {
        return("Password must have a number");
    } else if (user_password.search(/[a-zA-Z]/) === -1) {
        return("Password must have a letter");
    } else if (user_password.search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
        return("Invalid password character");
    }

	if(user_password !== passwordConfirmation) {
		return 'Password confirmation must match';
	}

    var phoneRegex = /^0+\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
	if (!user_phone_number) {
		return 'Phone number is required';
	} else if (phoneRegex.test(user_phone_number)) {
		return 'Phone number must have 9 digits without area code';
	}

	if (!user_phone_prefix) {
		return 'Phone number prefix is required';
	} else if (user_phone_prefix !== '+61' && user_phone_prefix !== '+64' && user_phone_prefix !== '+65') {
		return 'Phone number prefix must be +61 (AU) +64 (NZ) or +65 (SG)';
	}

	return null;
}

function ABNValidation (val) {
    val = val.replace(/[^0-9]/g, '');
    let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    if (val.length === 11) { 
        let sum = 0;
        weights.forEach(function(weight, position) {
            let digit = val[position] - (position ? 0 : 1);
            sum += weight * digit;
        });
        return sum % 89 === 0;
    }
    return false;
}