import { axiosAuth, getHeaders } from "./axios";
import { setWithExpiry } from "../context/MyLocalStorage";

export const getUser = async (user_id) => {
  if (!user_id) {
    user_id = localStorage.getItem("user_id");
  }

  const USER_URL = "/ambassadors/" + user_id.toString();
  try {
    const { data } = await axiosAuth.get(USER_URL, { headers: getHeaders() });

    return data;
  } catch (errors) {
    console.error(errors);
  }
};

export const getCharityInformation = async (user_id) => {
  if (!user_id) {
    user_id = localStorage.getItem("user_id");
  }

  const CHARITY_URL = "/charities/" + user_id.toString();

  try {
    const { data } = await axiosAuth.get(CHARITY_URL, { headers: getHeaders() });

    return data;
  } catch (errors) {
    console.error(errors);
  }
};

export const getUserWalletBalance = async () => {
  const WALLET_URL = "/wallets/";
  const user_object = JSON.parse(localStorage.getItem("user_object"));

  try {
    const { data } = await axiosAuth.get(WALLET_URL + user_object.wallet_id + "/balance/erths", {
        headers: getHeaders(),
      });

    return data;
  } catch (errors) {
    console.error(errors);
  }
};

export const updateUserPassword = async (newPwd, userType) => {
  let USERS_URL;
  let request_body;
  const user_id = localStorage.getItem("user_id");

  if(userType === "ambassador") {
    USERS_URL = "/ambassadors/"
    request_body = { ambassador_password: newPwd }
  } else {
    USERS_URL = "/charities/";
    request_body = { charity_password: newPwd }
  }

  try {
    const { data } = await axiosAuth.put(
      USERS_URL + user_id,
      request_body,
      { headers: getHeaders() }
    );
    setWithExpiry("token", data?.token, 30);
    return data;
  } catch (errors) {
    console.error(errors);
  }
};

export const updateUser = async (request_body, userType) => {
  let USERS_URL;
  const user_id = localStorage.getItem("user_id");

  if(userType === "ambassador") {
    USERS_URL = "/ambassadors/"
  } else {
    USERS_URL = "/charities/";
  }

  try {
    const response = await axiosAuth.put(
      USERS_URL + user_id,
      JSON.stringify(request_body),
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    console.log("updateUser err: ", err)
    let data = { errorMessage: "" };
    if (!err?.response) {
      data.errorMessage = "No Server Response";
    } else {
      data.errorMessage = "Update user failed";
    }
    return { data };
  }
};

export const login = async (user, pwd, userType) => {
  let LOGIN_URL = "/login";
  let login_body = {}

  login_body = {
    ambassador_email: user,
    ambassador_password: pwd,
    type: "ambassador",
  }


  if(userType === "charity") {
    login_body = {
      charity_email: user,
      charity_password: pwd,
      type: "charity",
    }
  }

  try {
    const response = await axiosAuth.post(
      LOGIN_URL,
      JSON.stringify(login_body),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    setWithExpiry("token", response?.data?.token, 30);
    localStorage.setItem("user_type", userType);
    localStorage.setItem("user_email", user);
    if(userType === "ambassador") {
      localStorage.setItem("user_id", response?.data?.ambassador_id);
    } else {
      localStorage.setItem("user_id", response?.data?.charity_id);
    }

    getUser().then((data) => localStorage.setItem( "user_object", JSON.stringify(data) ) );

    return response;
  } catch (err) {
    console.log("login err: ", err)
    let data = { errorMessage: "" };
    if (!err?.response) {
      data.errorMessage = "No Server Response";
    } else if (err.response?.status === 400) {
      data.errorMessage = "Missing Username or Password";
    } else if (err.response?.status === 401) {
      data.errorMessage = "Unauthorized";
    } else {
      data.errorMessage = "Login Failed";
    }
    return { data };
  }
};

export const createNewAmbassador = async (request_body) => {
  const user_id = localStorage.getItem("user_id");
  const USERS_URL = "/ambassadors";
  let response = null;

  try {
    if(!user_id) {
      response = await axiosAuth.post(
        USERS_URL,
        JSON.stringify(request_body),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      const user_object = JSON.parse(localStorage.getItem("user_object"));
      response = await axiosAuth.post(
          USERS_URL,
          JSON.stringify( addPromoCodeToUserCreationBody( request_body, user_object.ambassador_promocode )),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
    }

    return response;
  } catch (err) {
    console.log("Response err: ", err);
    let response = { data: { errorMessage: "" } } ;
    if (!err?.response) {
      response.data.errorMessage = "No Server Response";
    } else if (err.response?.status === 500) {
      response.data.errorMessage = "User already exists";
    } else if (err.response?.status === 401) {
      response.data.errorMessage = "Unauthorized";
    } else {
      response.data.errorMessage = "User creation Failed";
    }

    return response;
  }
};

export const createNewCharity = async (request_body) => {
  const USERS_URL = "/charities";

  console.log("createNewCharity request_body: ", request_body)

  try {

    const response = await axiosAuth.post(
      USERS_URL,
      JSON.stringify(request_body),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    console.log("createNewCharity response: ", response)
    
    return response;
  } catch (err) {
    let response = { data: { errorMessage: "" } } ;
    if (!err?.response) {
      response.data.errorMessage = "No Server Response";
    } else if (err.response?.status === 500) {
      response.data.errorMessage = "User already exists";
    } else if (err.response?.status === 401) {
      response.data.errorMessage = "Unauthorized";
    } else {
      response.data.errorMessage = "User creation Failed";
    }

    return response;
  }
};

function setUserAddress(address_obj) {
  if(address_obj?.address?.unit) {
    return address_obj?.address?.unit + '/' + address_obj?.address?.houseNumber + " " + address_obj?.address?.street
  } else {
    return address_obj?.address?.houseNumber + " " + address_obj?.address?.street
  }
}

export function adaptAmbassadorCreationBody(request_body) {
  console.log("adaptAmbassadorCreationBody request_body:", request_body)

  const new_ambassador = {
		ambassador_first_name: request_body.user_first_name,
		ambassador_last_name: request_body.user_last_name,
		ambassador_email: request_body.user_email,
		ambassador_password: request_body.user_password,
		ambassador_phone_number: adaptPhoneNumber(request_body.user_phone_number),
		ambassador_phone_prefix: request_body.user_phone_prefix,
		promoted_by_code: request_body.promoted_by_code,
		ambassador_country: request_body.address?.address?.countryName,
		ambassador_city: request_body.address?.address?.city,
    ambassador_postal_code: request_body.address?.address?.postalCode,
    ambassador_state: request_body.address?.address?.state,
    ambassador_address: setUserAddress(request_body?.address),
    ambassador_abn: request_body.abn,
    ambassador_business_name: request_body.business_name
	};

  console.log("adaptAmbassadorCreationBody new_user: ", new_ambassador)

  return new_ambassador
}

export function adaptCharityCreationBody(request_body) {

  const new_charity = {
    charity_name: request_body.charity_name,
    members_quantity: request_body.members_quantity,
		first_name_contact_person: request_body.first_name_contact_person,
		last_name_contact_person: request_body.last_name_contact_person,
		charity_email: request_body.charity_email,
		charity_password: request_body.charity_password,
		charity_phone_number: adaptPhoneNumber(request_body.charity_phone_number),
		charity_phone_prefix: request_body.charity_phone_prefix,
		promoted_by_code: request_body.promoted_by_code,
		charity_country: request_body.address?.address?.countryName,
		charity_city: request_body.address?.address?.city,
    charity_postal_code: request_body.address?.address?.postalCode,
    charity_state: request_body.address?.address?.state,
    charity_address: setUserAddress(request_body?.address)
	};

  return new_charity
}

export function adaptPhoneNumber(phoneNumber){
  if(!(/^0/.test(phoneNumber))) {
    return '0' + phoneNumber;
  } else {
    return phoneNumber;
  }
}

function addPromoCodeToUserCreationBody(request_body, promo_code) {

  if(!request_body.promoted_by_code) 
    request_body.promoted_by_code = promo_code;

  return request_body
}