import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import Card from "../dashboard/InformationCardComponent";
import { getUserWalletBalance } from "../../api/UserService";
import { useState, useEffect } from "react";
import StyledTable from "../../components/common/StyledTable";
import { NameColumn, Name, TableHeader } from "./styles";
import { getWalletHistoryData } from "./WalletService";
import Container from "@mui/material/Container";

interface WalletHistoryInterface {
  id: number;
  operation_type: string;
  amount: string;
  date: string;
}

const columns = {
  operation_type: {
    key: "operation_type",
    label: "Operation type",
    content: (item: WalletHistoryInterface) => (
      <NameColumn>
        <Name>{item.operation_type}</Name>
      </NameColumn>
    ),
  },
  amount: {
    key: "amount",
    label: "Amount",
    content: (item: WalletHistoryInterface) => (
      <NameColumn>
        <Name>{item.amount}</Name>
      </NameColumn>
    ),
  },
  date: {
    key: "date",
    label: "Date",
    content: (item: WalletHistoryInterface) => (
      <NameColumn>
        <Name>{item.date}</Name>
      </NameColumn>
    ),
  },
};

const rowConfig = {
  uniqueKey: "id",
  css: `
    height: 30px;
    &:hover {
      background-color: rgba(216, 216, 216, 0.2)};
    }
  `,
  onClick: (e: any, item: WalletHistoryInterface) => {
    console.log("Row was clicked", item);
  },
};

const MyWalletPage = () => {
  const [WalletValue, setWalletValue] = useState<any | null>(null);
  const [HistoryWalletData, setHistoryWalletData] = useState<any | null>([]);

  useEffect(() => {
    // Can implement a <Loading/>
    //  start loading
    if (!WalletValue) {
      getUserWalletBalance().then((data) => setWalletValue(data.balance));
    }
    if (HistoryWalletData.length === 0) {
      getWalletHistoryData().then((data) => setHistoryWalletData(data));
    }
    //  finish loading
  }, [WalletValue, setWalletValue, HistoryWalletData, setHistoryWalletData]);

  const virtualWalletProps = {
    headerTitle: "VIRTUAL WALLET",
    bodyValue: WalletValue,
    bodyIcon: "AttachMoney",
    bgHeaderColor: "#41B5DE",
    bgBodyColor: "#2B9BC2",
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">MY WALLET</div>
        <div className="subtitle">Statement on your bonus wallet</div>
        <Container sx={{ width: { xs: "100%", md: "30%" }, marginBottom: "20px", marginLeft: "0%" }}>
          <Card {...virtualWalletProps} />
        </Container>
        <TableHeader $inputColor={"#17D8B2"}>
          OPERATION HISTORY
        </TableHeader>
        <StyledTable
          rowConfig={rowConfig}
          columns={columns}
          data={HistoryWalletData}
        />
      </StyledDivPage>
    </StyledContainer>
  );
};

export default MyWalletPage;
