export default function validateForm(
	{ 
		charity_number,
		address,
		charity_email,
		charity_name,
		charity_password,
		first_name_contact_person,
		last_name_contact_person,
		charity_phone_number,
		charity_phone_prefix,
		abn,
		website,
		category,
		members_quantity
	}, passwordConfirmation) {

	const abnReg = /^\d{11}$/;
	const numberReg = /^\d+$/;

	function isEmpty(obj) {
		for (const prop in obj) {
		  if (Object.hasOwn(obj, prop)) {
			return false;
		  }
		}
	  
		return true;
	}

	if (!charity_name?.trim()) {
		return 'Charity name required';
	}
	if (!abn?.trim()) {
		return 'ABN number required';
	} else if ( !abnReg.test( abn ) ) {
		return 'ABN must be a number of 11 digits';
	}
	if( !charity_number?.trim() ) {
		return 'Charity number required'
	} else if( !numberReg.test( charity_number ) ) {
		return 'Charity number must be a number'
	}
	if (!website?.trim()) {
		return 'Website required';
	}
	if (!category?.trim()) {
		return 'Choose a category';
	}
	if (!members_quantity?.trim()) {
		return 'Choose a number of members';
	}
	if( isEmpty( address ) ) {
		return 'Address is required';
	}
	if (!first_name_contact_person?.trim()) {
		return 'First name contact required';
	}
    if (!last_name_contact_person?.trim()) {
		return 'Last name contact required';
	}

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!charity_email) {
		return 'Email required';
	} else if (emailRegex.test(charity_email.toLocalLowerCase)) {
		return 'Email address is invalid';
	}

	if (!charity_password) {
		return 'Password required';
	} else if (charity_password.length < 8) {
        return("Password must have minimum 8 characters");
    } else if (charity_password.length > 50) {
        return("Password must have maximum 50 characters");
    } else if (charity_password.search(/\d/) === -1) {
        return("Password must have a number");
    } else if (charity_password.search(/[a-zA-Z]/) === -1) {
        return("Password must have a letter");
    } else if (charity_password.search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
        return("Invalid password character");
    }

	if(charity_password !== passwordConfirmation) {
		return 'Password confirmation must match';
	}

    var phoneRegex = /^0+\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
	if (!charity_phone_number) {
		return 'Phone number is required';
	} else if (phoneRegex.test(charity_phone_number)) {
		return 'Phone number must have 9 digits without area code';
	}

	if (!charity_phone_prefix) {
		return 'Phone number prefix is required';
	} else if (charity_phone_prefix !== '+61' && charity_phone_prefix !== '+64' && charity_phone_prefix !== '+65') {
		return 'Phone number prefix must be +61 (AU) +64 (NZ) or +65 (SG)';
	}

	return null;
}