import { Box, Grid, Paper } from "@mui/material";
import Sales from "./SalesComponent";
import Card from "./InformationCardComponent";
import * as dashboardService from '../../api/SalesServices';
import { useState, useEffect } from 'react';
import { StyledContainer, StyledDivPage } from "../../components/common/StyledSection";
import assets from "../../assets/index";
import { getUserWalletBalance } from '../../api/UserService'

const currentPeriodSalesComponentProps = { 
  title: dashboardService.getCurrentPeriodSalesComponentTitle(),
  volumeName: "Sales Volume",
  volume: "0",
  bgHeaderColor: "#3c6cdd"
}

const totalSalesComponentProps = { 
  title: dashboardService.getTotalSalesComponentTitle(),
  volumeName: "Accumulated Sales Volume",
  volume: "0",
  bgHeaderColor: "#1BC17D"
}

const DashboardPage = () => {
  const [CPVolume, setCPVolume] = useState<any | null>(null);
  const [TotalVolume, setTotalVolume] = useState<any | null>(null);
  const [WalletValue, setWalletValue] = useState<any | null>(0);

  useEffect(() => {
      // Can implement a <Loading/>
      //  start loading
      if(!CPVolume) {
        dashboardService.getCurrentPeriodSalesVolume().then(data => setCPVolume(data))
        dashboardService.getTotalSalesVolume().then(data => setTotalVolume(data))
      }

      if(!WalletValue) {
        getUserWalletBalance().then(data => setWalletValue(data.balance))
      }
      //  finish loading
  }, [CPVolume, TotalVolume, setCPVolume, setTotalVolume, WalletValue, setWalletValue])

  currentPeriodSalesComponentProps.volume = CPVolume?.total ? CPVolume.total.toFixed(2).toString() : "0";
  totalSalesComponentProps.volume = TotalVolume?.total ? TotalVolume.total.toFixed(2).toString() : "0";

  const ambassadorStatusProps = { 
    headerTitle: "AMBASSADOR STATUS",
    bodyValue: "Ambassador",
    bodyIcon: "Person",
    bgHeaderColor: "#1AE08F",
    bgBodyColor: "#18CB82"
  }
  
  const affiliateCommissionProps = { 
    headerTitle: "AFFILIATE COMMISSION",
    bodyValue: "0",
    bodyIcon: "Groups",
    bgHeaderColor: "#18F1C6",
    bgBodyColor: "#17D8B2"
  }
  
  const downlineProps = { 
    headerTitle: "DOWNLINE",
    bodyValue: "2",
    bodyIcon: "Star",
    bgHeaderColor: "#975BF4",
    bgBodyColor: "#8751DB"
  }
  
  const virtualWalletProps = { 
    headerTitle: "VIRTUAL WALLET",
    bodyValue: WalletValue,
    bodyIcon: "AttachMoney",
    bgHeaderColor: "#41B5DE",
    bgBodyColor: "#2B9BC2"
  }

  return (
    <StyledContainer>
      <StyledDivPage>
      <div className="title">DASHBOARD</div>
      <div className="subtitle">Here you can see an instant snapshop of your business</div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
            <Paper>
              <Sales 
                {...currentPeriodSalesComponentProps}
              />
            </Paper>
            <div style={{ marginBottom: "10px" }} />
            <Paper>
              <Sales 
                {...totalSalesComponentProps}
              />
            </Paper>
        </Grid>  
        <Grid item xs={12} sm={12} md={6} display={{ xs: "none", md: "block" }}>
          <Box display="flex" height="290px">
            <Paper style={{ flexGrow: 1 }}>
              <img src={assets.images.dashboard_relations} alt="Relations graph" height="100%" width="auto"/>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <div style={{ margin: '20px 0' }}></div>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <Card
           {...ambassadorStatusProps}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
           {...affiliateCommissionProps}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
           {...downlineProps}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
           {...virtualWalletProps}
          />
        </Grid>
      </Grid>
      </StyledDivPage>
    </StyledContainer>
  );
};

export default DashboardPage;
