import DashboardPage from "../pages/dashboard/DashboardPage";
import { RouteType } from "./config";
import ProfilePage from "../pages/profile/ProfilePage";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BuyCoursesPage from "../pages/courses/BuyCoursesPage";
import MyReferrals from "../pages/referrals/MyReferrals";
import NetworkIndexPage from "../pages/network/NetworkIndexPage";
import MyNetworkPage from "../pages/network/table/MyNetworkPage";
import CoursesIndex from "../pages/courses/CoursesIndex";
import NetworkChartPage from "../pages/network/tree-graph/NetworkChartPage";
import RegisterNewMemberpage from "../pages/register/RegisterNewMemberpage";
import MyWalletPage from "../pages/wallet/MyWalletPage";
import PayoutIndexPage from "../pages/payout/PayoutIndexPage";
import PayoutListPage from "../pages/payout/PayoutListPage";
import NewPayoutRequestPage from "../pages/payout/NewPayoutRequestPage";
import InformationIndex from "../pages/information/InformationIndex";
import MaterialsInformationPage from "../pages/information/MaterialsInformationPage";
import BusinessInformationPage from "../pages/information/business/BusinessInformationPage";
import InfoIcon from '@mui/icons-material/Info';
import PaymentsIcon from '@mui/icons-material/Payments';
import WalletIcon from '@mui/icons-material/Wallet';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HubIcon from '@mui/icons-material/Hub';
import GroupIcon from '@mui/icons-material/Group';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Logout } from "../pages/login/Logout";
import LogoutIcon from '@mui/icons-material/Logout';
import ChangePassword from '../pages/profile/ChangePassword';
import { AmbassadorsPrivateRoute } from './AmbassadorsPrivateRoute';
import { CharitiesPrivateRoute } from './CharitiesPrivateRoute';
import { PrivateRoute }  from './PrivateRoute';
import Redirect from "./Redirect";
import StarIcon from '@mui/icons-material/Star';
import CharityProfilePage from "../pages/profile/charity/CharityProfilePage";
import PaymentSuccessNewMember from "../pages/register/PaymentSuccessNewMember";
import SignUpPayment from "../pages/signup/SignUpPayment";
import SignUpFailure from "../pages/signup/SignUpFailure";
import PaymentFailure from "../pages/signup/PaymentFailure";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: 
      <AmbassadorsPrivateRoute>
        <DashboardPage />
      </AmbassadorsPrivateRoute>
    ,
    state: "home",
    user_type: "ambassador"
  },
  {
    index: true,
    element: 
      <CharitiesPrivateRoute>
        <CharityProfilePage />
      </CharitiesPrivateRoute>
    ,
    state: "home",
    user_type: "charity"
  },
  {
    path: "/changepassword",
    element:  <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>,
    state: "changepassword",
    user_type: "all"
  },
  {
    path: "/signupfailure",
    element:  <PrivateRoute>
                <SignUpFailure />
              </PrivateRoute>,
    state: "changepassword",
    user_type: "ambassador"
  },
  {
    path: "/paymentfailure",
    element:  <PrivateRoute>
                <PaymentFailure />
              </PrivateRoute>,
    state: "changepassword",
    user_type: "ambassador"
  },
  {
    path: "/paymentsuccessnewmember",
    element:  <PrivateRoute>
                <PaymentSuccessNewMember />
              </PrivateRoute>,
    state: "changepassword",
    user_type: "ambassador"
  },
  {
    path: "/signuppayment",
    element:  <PrivateRoute>
                <SignUpPayment />
              </PrivateRoute>,
    state: "changepassword",
    user_type: "ambassador"
  },
  {
    path: "/payoutlistpage",
    element:  <AmbassadorsPrivateRoute>
                <PayoutListPage />
              </AmbassadorsPrivateRoute>,
    state: "payoutlist",
    user_type: "ambassador"
  },
  {
    path: "/erthcrm",
    element:  <AmbassadorsPrivateRoute>
                <Redirect url={ "https://inet.rewards4earth.com/index.php" } />
              </AmbassadorsPrivateRoute>,
    state: "erthcrm",
    sidebarProps: {
      displayText: "ERTH CRM",
      icon: <StarIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/canvas",
    element:  <AmbassadorsPrivateRoute>
                <Redirect url={ "https://www.instructure.com/en-au/canvas/login/free-for-teacher" } />
              </AmbassadorsPrivateRoute>,
    state: "canvas",
    sidebarProps: {
      displayText: "BDM Course 2023",
      icon: <StarIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/dashboard",
    element:  <AmbassadorsPrivateRoute>
                <DashboardPage />
              </AmbassadorsPrivateRoute>,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardOutlinedIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/profile",
    element:  <AmbassadorsPrivateRoute>
                <ProfilePage />
              </AmbassadorsPrivateRoute>,
    state: "profile",
    sidebarProps: {
      displayText: "Profile",
      icon: <PermIdentityIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/charityprofile",
    element:  <CharitiesPrivateRoute>
                <CharityProfilePage />
              </CharitiesPrivateRoute>,
    state: "profile",
    sidebarProps: {
      displayText: "Profile",
      icon: <PermIdentityIcon />
    },
    user_type: "charity"
  },
  {
    path: "/courses",
    element:  <AmbassadorsPrivateRoute>
                <CoursesIndex />
              </AmbassadorsPrivateRoute>,
    state: "courses",
    // sidebarProps: {
    //   displayText: "Courses",
    //   icon: <MenuBookIcon />
    // },
    child: [
      {
        path: "/courses/buy",
        element:  <AmbassadorsPrivateRoute>
                    <BuyCoursesPage />
                  </AmbassadorsPrivateRoute>,
        state: "courses.buy",
        // sidebarProps: {
        //   displayText: "Buy Courses"
        // },
        user_type: "ambassador"
      }
    ],
    user_type: "ambassador"
  },
  {
    path: "/referrals",
    element:  <AmbassadorsPrivateRoute>
                <MyReferrals />
              </AmbassadorsPrivateRoute>,
    state: "myreferrals",
    sidebarProps: {
      displayText: "My referrals",
      icon: <GroupIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/network",
    element:  <AmbassadorsPrivateRoute>
                <NetworkIndexPage />
              </AmbassadorsPrivateRoute>,
    state: "network",
    sidebarProps: {
      displayText: "Network",
      icon: <HubIcon />
    },
    child: [
      {
        path: "/network/mynetwork",
        element:  <AmbassadorsPrivateRoute>
                    <MyNetworkPage />
                  </AmbassadorsPrivateRoute>,
        state: "network.mynetwork",
        sidebarProps: {
          displayText: "My network"
        },
        user_type: "ambassador"
      },
      {
        path: "/network/chart",
        element:  <AmbassadorsPrivateRoute>
                    <NetworkChartPage />
                  </AmbassadorsPrivateRoute>,
        state: "network.chart",
        sidebarProps: {
          displayText: "Network chart"
        },
        user_type: "ambassador"
      }
    ],
    user_type: "ambassador"
  },
  {
    path: "/register",
    element:  <AmbassadorsPrivateRoute>
                <RegisterNewMemberpage />
              </AmbassadorsPrivateRoute>,
    state: "register",
    sidebarProps: {
      displayText: "Register new member",
      icon: <AppRegistrationIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/wallet",
    element:  <AmbassadorsPrivateRoute>
                <MyWalletPage />
              </AmbassadorsPrivateRoute>,
    state: "wallet",
    sidebarProps: {
      displayText: "My wallet",
      icon: <WalletIcon />
    },
    user_type: "ambassador"
  },
  {
    path: "/payout",
    element:  <AmbassadorsPrivateRoute>
                <PayoutIndexPage />
              </AmbassadorsPrivateRoute>,
    state: "payout",
    sidebarProps: {
      displayText: "Payout",
      icon: <PaymentsIcon />
    },
    child: [
      {
        path: "/payout/list",
        element:  <AmbassadorsPrivateRoute>
                    <PayoutListPage />
                  </AmbassadorsPrivateRoute>,
        state: "payout.list",
        sidebarProps: {
          displayText: "List"
        },
        user_type: "ambassador"
      },
      {
        path: "/payout/request",
        element:  <AmbassadorsPrivateRoute>
                    <NewPayoutRequestPage />
                  </AmbassadorsPrivateRoute>,
        state: "payout.request",
        sidebarProps: {
          displayText: "New payout request"
        },
        user_type: "ambassador"
      }
    ],
    user_type: "ambassador"
  },
  {
    path: "/information",
    element:  <AmbassadorsPrivateRoute>
                <InformationIndex />
              </AmbassadorsPrivateRoute>,
    state: "information",
    sidebarProps: {
      displayText: "Information",
      icon: <InfoIcon />
    },
    child: [
      {
        path: "/information/materials",
        element:  <AmbassadorsPrivateRoute>
                    <MaterialsInformationPage />
                  </AmbassadorsPrivateRoute>,
        state: "information.materials",
        // sidebarProps: {
        //   displayText: "Materials"
        // },
        user_type: "ambassador"
      },
      {
        path: "/information/business",
        element:  <AmbassadorsPrivateRoute>
                    <BusinessInformationPage />
                  </AmbassadorsPrivateRoute>,
        state: "information.business",
        sidebarProps: {
          displayText: "Business"
        },
        user_type: "ambassador"
      }
    ],
    user_type: "ambassador"
  },
  {
    path: "/logout",
    element:  <PrivateRoute>
                <Logout />
              </PrivateRoute>,
    state: "logout",
    sidebarProps: {
      displayText: "Logout",
      icon: <LogoutIcon />
    },
    user_type: "all"
  }
];

export default appRoutes;