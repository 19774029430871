import React from 'react'

type Props = {}

const MaterialsInformationPage = (props: Props) => {
  return (
    <div>MaterialsInformationPage</div>
  )
}

export default MaterialsInformationPage