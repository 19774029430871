import { CardButtonSection } from './styles';
import Button from '@mui/material/Button';

interface IProps {
    linkToBuy: string
}

export default function CardComponentButtonSection( props: IProps ) {
    return (
        <CardButtonSection>
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                href={props.linkToBuy}
            >
                Buy now
            </Button>
        </CardButtonSection>
    )
};