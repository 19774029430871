import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import { Grid } from "@mui/material";
import Card from './CourseCardComponent';

const styling = {
  container: {
    paddingRight: { md: "12" },
    wrap: "nowrap"
  }
}

const BuyCoursesPage = () => {
  const basicCourseProps = { 
    headerTitle: "Basic",
    bgHeaderColor: "#000000",
    bodyValue: "500",
    bgBodyColor: "#23ACF6",
    firstBodySection: "Basic ambassador business",
    secondBodySection: "Referral bonus",
    thirdBodySection: "",
    linkToBuy: "#"
  }

  const advancedCourseProps = { 
    headerTitle: "Advanced",
    bgHeaderColor: "#000000",
    bodyValue: "500",
    bgBodyColor: "#32D360",
    firstBodySection: "Advanced courses",
    secondBodySection: "Referral bonus",
    thirdBodySection: "Team bonus 10%",
    linkToBuy: "#"
  }

  const businessCourseProps = { 
    headerTitle: "Business",
    bgHeaderColor: "#000000",
    bodyValue: "500",
    bgBodyColor: "#385AE3",
    firstBodySection: "Business courses",
    secondBodySection: "Referral bonus",
    thirdBodySection: "",
    linkToBuy: "#"
  }

  const clubCourseProps = { 
    headerTitle: "Club",
    bgHeaderColor: "#000000",
    bodyValue: "500",
    bgBodyColor: "#E47F20",
    firstBodySection: "Club and charity courses",
    secondBodySection: "Referral bonus",
    thirdBodySection: "",
    linkToBuy: "#"
  }

  const clubTBACourseProps = { 
    headerTitle: "Club",
    bgHeaderColor: "#000000",
    bodyValue: "29.95",
    bgBodyColor: "#E47F20",
    firstBodySection: "TBA Product Name",
    secondBodySection: "Referral bonus",
    thirdBodySection: "",
    linkToBuy: "#"
  }

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">BUY COURSES</div>
        <div className="subtitle">Here you can purchase Erth Ambassadors courses for affiliates and businesses participating in the program</div>
      </StyledDivPage>
      <div style={{ margin: '30px 0' }}></div>
      <Grid sx={ styling.container } container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            {...basicCourseProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            {...advancedCourseProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            {...businessCourseProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            {...clubCourseProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            {...clubTBACourseProps}
          />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default BuyCoursesPage;