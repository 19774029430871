import React from 'react'
import { Outlet } from 'react-router-dom';

type Props = {}

const CoursesIndex = (props: Props) => {
  return (
    <><Outlet /></>
  );
};

export default CoursesIndex;