import { useState, useEffect } from 'react';
import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import { getReferralsData } from './ReferralsService';
import StyledTable from "../../components/common/StyledTable";
import { TableHeader, NameColumn, Name } from "../wallet/styles";
import ReferralTable from "./ReferralTable";
import { Grid } from "@mui/material";

interface ReferralInterface {
  id: number;
  full_name: string;
  email: string;
  country: string;
  region: string;
  sales_volume: string;
  transactional_volume: string;
  personal_volume: string;
  ambassador_status: string;
  account_type: string;
}

const columns = {
  full_name: {
    key: "full_name",
    label: "Referral name",
    content: (item: ReferralInterface) => (
      <NameColumn>
        <Name>{item.full_name}</Name>
      </NameColumn>
    ),
  },
  
};

const MyReferrals = () => {
  const [ReferralsData, setReferralsData] = useState<any | null>([]);
  const [UserID, setUserID] = useState(0);

  useEffect(() => {
    // Can implement a <Loading/>
    //  start loading
    if (ReferralsData.length === 0) {
      getReferralsData().then((data) => setReferralsData(data));
    }
    if( UserID === 0 && ReferralsData.length !== 0) {
      setUserID(ReferralsData[0].id);
    }
    //  finish loading
  }, [ReferralsData, setReferralsData, UserID, setUserID]);

  const rowConfig = {
    uniqueKey: "id",
    css: `
      height: 40px;
      &:hover {
        background-color: rgba(216, 216, 216, 0.2)};
      }
    `,
    onClick: (e: any, item: ReferralInterface) => {
      setUserID(item.id)
    },
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">MY REFERRALS</div>
        <div className="subtitle">Manage your frontline partners here</div>
        <TableHeader $inputColor={"#17D8B2"}>
          {"LIST OF REFERRALS"}
        </TableHeader>
        <div style={{ margin: '30px 0' }}></div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5} >
            <StyledTable
              rowConfig={rowConfig}
              columns={columns}
              data={ReferralsData}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <ReferralTable user_id={UserID} />
          </Grid>
        </Grid>
      </StyledDivPage>
    </StyledContainer>
  );
};

export default MyReferrals;
