import styled from "styled-components";

export const TableHeader = styled.div<{ $inputColor?: string }>`
  background: ${props => props.$inputColor || "#3c6cdd"};
  height: 45px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  padding: 0.25em 0.5em;
  font-weight: 550;
`;

export const NameColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Name = styled.span`
  margin-left: 8px;
`;