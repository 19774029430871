import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "1rem",
            top: "-1",
          }),
        }),
      },
    },
  },
});

const PaymentFailure = () => {
  const location = useLocation();
  const user = location?.state?.user;
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(
      '/signuppayment',
      { state:
        {
          user: user
        }
      }
    );
}

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ErrorOutlineOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Your payment has failed
            </Typography>
            <div style={{ margin: '20px 0' }}></div>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                  Try again
              </Button>
            </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
};

export default PaymentFailure;