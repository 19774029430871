import { getWithExpiry } from '../context/MyLocalStorage';
import Login from '../pages/login/Login';
import { InvalidAmbassadorsRoute } from './InvalidAmbassadorsRoute';

export const AmbassadorsPrivateRoute = ({ children }) => {
    const token = getWithExpiry("token");
    const user_type = localStorage.getItem("user_type");

    if (!token) {
      // user is not authenticated
      return <Login />;
    }

    if(user_type !== "ambassador") {
      // user is not authenticated as an ambassador
      return <InvalidAmbassadorsRoute />
    }
    
    return children;
};
