import { Avatar } from "@mui/material";
import { AvatarDiv } from '../../components/common/StyledSection';
import React from "react";

function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
};
  
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: { xs: 30, md: 75 },
        width: { xs: 60, md: 150 }, 
        height: { xs: 60, md: 150 },
        margin: "auto"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
};

interface IUser {
  first_name: string,
  last_name: string,
  country: string,
  city: string,
  email: string,
  phone: string
}

export default function ProfilePhoto( user: IUser ) {
    return (
      <AvatarDiv>
        <Avatar {...stringAvatar( user.first_name + ' ' + user.last_name )} />
      </AvatarDiv>
    );
};