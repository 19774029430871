import React, { useState } from 'react';
import AmbassadorForm from './AmbassadorSignUpForm';
import CharityForm from './CharitySignUpForm';
import { StyledContainer, StyledDivPage } from "../../components/common/StyledSection";
import RadioButtonsGroupComponent from '../../components/login/RadioButtonsGroupComponent';
import { FormTitle, Container, FormRow, FormColumn } from '../../components/common/FormStyles';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const SignUp = () => {
  const location = useLocation();
  const isFree = location?.state?.isFree;
  const { promoCode } = useParams();
  const [userType, setUserType] = useState(null);
  const onChangeUserType = (newValue)=>{
		setUserType(newValue);
	}

  return (
    <StyledContainer>
      <StyledDivPage>
        <Container>
          <FormRow>
            <FormColumn>
              <FormTitle>Create new member</FormTitle>
              <RadioButtonsGroupComponent
                onChangeFunction={onChangeUserType}
              />
            </FormColumn>
          </FormRow>
        </Container>
        {userType === "ambassador" && (
          <AmbassadorForm promoCode={promoCode} isFree={isFree} />
        )}
        {userType === "charity" && (
          <CharityForm/>
        )}
      </StyledDivPage>
    </StyledContainer>
  )
};

export default SignUp;