import React, {useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { useLocation } from 'react-router-dom';
import { createNewAmbassador, adaptAmbassadorCreationBody } from '../../api/UserService';
import { getWithExpiry } from '../../context/MyLocalStorage';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "1rem",
            top: "-1",
          }),
        }),
      },
    },
  },
});

const SignUpPayment = () => {
  const location = useLocation();
  const user = location?.state?.user;
  const navigate = useNavigate();
	const [transactionStatus, setTransactionStatus] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const accessToken = getWithExpiry("token");

  useEffect(() => {
		window.paypal
			.Buttons({
			createOrder: (data, actions, err) => {
				return actions.order.create({
				purchase_units: [
					{
					description: "Sign Up BDM",
					amount: {
						currency_code: "AUD",
						value: 250.00,
					},
					},
				],
				});
			},
			onApprove: async (data, actions) => {
				const order = await actions.order.capture();
	
				console.log("success", order);
				setTransactionStatus("success");
			},
			onError: (err) => {
				console.log(err);
				setTransactionStatus("paymentFailure");
			},
			})
			.render('#paypal-button-container');
	}, []);

  useEffect(() => {
    console.log("useEffect responseData: ", responseData)
    if( responseData ) {
      if( responseData?.errorMessage ) {
        navigate(
          '/signupfailure',
          { state:
            {
              error: responseData?.errorMessage
            }
          }
        );
      } else {
        if(accessToken) {
            navigate(
              '/paymentsuccessnewmember', 
              { state:
                {
                  user_name: user?.user_first_name
                }
              }
            );
        } else {
            navigate(
              '/paymentsuccess', 
              { state:
                {
                  user_name: user?.user_first_name
                }
              }
            );
        }    
      }
    }
  }, [responseData])

  useEffect(() => {
    if (transactionStatus === "success") {
      console.log("success user: ", user);
  
      createNewAmbassador( adaptAmbassadorCreationBody(user) ).then(response => setResponseData(response.data));
  
    }
  
    if (transactionStatus === "paymentFailure") {
      console.log("failure");
  
      navigate(
        '/paymentfailure',
        { state:
          {
            user: user
          }
        }
      );
    }
  }, [transactionStatus])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <PaymentOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Pay $250 to complete your registration
            </Typography>
            <div style={{ margin: '20px 0' }}></div>
            <Box component="form" sx={{ mt: 1 }}>
                <div>
                  <div id="paypal-button-container"></div>
                </div>
            </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
};

export default SignUpPayment;