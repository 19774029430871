import {
  StyledContainer,
  StyledDivPage,
} from "../../components/common/StyledSection";
import {
	FormMessage,
  FormErrorMessage
} from '../../components/common/FormStyles';
import TransactionsTable from "../../components/common/StyledTable";
import { useEffect, useState } from "react";
import { NameColumn, Name, TableHeader } from "../wallet/styles";
import { getPendingTransactionsData } from "./PayoutService";
import Checkbox from "./Checkbox";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { postNewPayout } from './PayoutService';
import { useNavigate } from "react-router-dom";

interface TransactionInterface {
  id: number;
  payment_id: string;
  date: string;
  amount: string;
  payment_system: string;
  selected: boolean;
}

const rowConfig = {
  uniqueKey: "id",
  css: `
    height: 73px;
    &:hover {
      background-color: rgba(216, 216, 216, 0.2)};
    }
  `,
  onClick: (e: any, item: TransactionInterface) => {},
};

function getTransactionsIDs(allTransactionsList: Array<TransactionInterface>) {
  let selectedTransactions: Array<TransactionInterface> = []

  if(allTransactionsList.length !== 0) {
    selectedTransactions = allTransactionsList.filter(transaction => transaction.selected);
  }

  const selectedTransactionsIDs = [];

  for (const obj of selectedTransactions) {
		selectedTransactionsIDs.push(obj.payment_id);
	}

  return selectedTransactionsIDs;
}

const NewPayoutRequestPage = () => {
  const navigate = useNavigate();
  const [PayoutData, setPayoutData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Can implement a <Loading/>
    //  start loading
    if (PayoutData.length === 0) {
      getPendingTransactionsData().then((data: any) => setPayoutData(data));
    }

    //  finish loading
  }, [PayoutData, setPayoutData]);

  useEffect(() => {
    if (success === true) {
      navigate('/payoutlistpage');
    }
  }, [success, navigate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  
    const selectedTransactionsIDs = getTransactionsIDs(PayoutData);
  
    const { data } = postNewPayout(selectedTransactionsIDs).then(data => data);
  
    if( !data || data?.errMessage ) {
      if(data?.errMessage) {
        setError(data.errMessage);
      } else {
        setError("Error");
      }
      return;
    } else {
    setTotalAmount(0);
    setError('');
    setSuccess(true);
    }
  
  };

  const columns = {
    selected: {
      key: "selected",
      label: "Select",
      content: (item: TransactionInterface) => (
        <Checkbox
          item={item}
          totalAmount={totalAmount}
          totalAmountSetter={setTotalAmount}
        />
      ),
    },
    payment_id: {
      key: "payment_id",
      label: "Payment ID",
      content: (item: TransactionInterface) => (
        <NameColumn>
          <Name>{item.payment_id}</Name>
        </NameColumn>
      ),
    },
    request_date: {
      key: "date",
      label: "Payment date",
      content: (item: TransactionInterface) => (
        <NameColumn>
          <Name>{item.date}</Name>
        </NameColumn>
      ),
    },
    amount: {
      key: "amount",
      label: "Amount",
      content: (item: TransactionInterface) => (
        <NameColumn>
          <Name>{item.amount}</Name>
        </NameColumn>
      ),
    },
    payment_system: {
      key: "payment_system",
      label: "Payment system",
      content: (item: TransactionInterface) => (
        <NameColumn>
          <Name>{item.payment_system}</Name>
        </NameColumn>
      ),
    },
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">PAYOUT</div>
        <div className="subtitle">Manage payout requests</div>
        <div style={{ marginBottom: "20px" }} />
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <div>Total amount: {totalAmount}</div>
          <div>
          {
            totalAmount !== 0 &&
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, maxWidth: { xs: "80%", sm: "30%" }, margin: 0 }}
              >
                Generate payout
              </Button>
            </Box>  
          }    
          </div>
        </div>
        {error && (
            <FormErrorMessage>
              {error}
            </FormErrorMessage>
          )}
          {success && (
            <FormMessage>
              {success}
            </FormMessage>
          )}
        <TableHeader $inputColor={"#17D8B2"}>
          {"SELECT TRANSACTIONS TO MAKE A PAYOUT"}
        </TableHeader>
        <TransactionsTable
          rowConfig={rowConfig}
          columns={columns}
          data={PayoutData}
        />
      </StyledDivPage>
    </StyledContainer>
  );
};

export default NewPayoutRequestPage;
