import axios from 'axios';
import { getWithExpiry } from '../context/MyLocalStorage';

export const axiosAuth = axios.create({
  baseURL: 'https://api.rewards4earth.com/api/v1'
});

export function getHeaders() {
  const headers = {
    Accept: 'application/json'
  };

  const accessToken = getWithExpiry("token");
  if (accessToken) {
      headers.Authorization = 'Bearer ' + accessToken;
  };
  return headers;
};