import { CardBody } from './styles';
import DynamicIcon from './DynamicIcon'

interface IProps {
    value: string
    icon: string
    bgColor: string
}

export default function CardComponentBody( props: IProps ) {
    return (
        <CardBody $inputColor={props.bgColor}>
            <div>
                <div style={{ margin: '10px 0' }}></div>
                <DynamicIcon iconName={props.icon} />
            </div>
            <div>{props.value}</div>
        </CardBody>
    )
};