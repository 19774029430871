import * as icons from '@mui/icons-material'
import stringSimilarity from 'string-similarity'


export default function DynamicIcon({ iconName }) {
  const Icon =  useIcons( iconName )
  return <Icon fontSize="large"/>
};

function useIcons(word) {
  const iconsNames = Object.keys(icons)

  var matches = stringSimilarity.findBestMatch(word, iconsNames)
  const bestMathch = matches.bestMatch.target
  const Icon = icons[bestMathch]
  return Icon
}