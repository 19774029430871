import React, { useState, useEffect } from 'react';
import {
	FormColumn,
	FormWrapper,
	FormInput,
	FormRow,
	FormLabel,
	FormInputRow,
	FormErrorMessage,
    Container,
	FormSelectDiv
} from '../../components/common/FormStyles';
import validateForm from './SignUpCharityValidateForm';
import Button from '@mui/material/Button';
import { createNewCharity, adaptCharityCreationBody } from '../../api/UserService';
import { useNavigate } from "react-router-dom";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AutocompleteAddressComponent } from '../../components/common/AutocompleAddressComponent';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Form = () => {
	const navigate = useNavigate();
	const [newUserData, setUserData] = useState({
		"charity_name": "",
		"abn": "",
		"charity_number": "",
		"website": "",
		"category": "",
		"charity_email": "",
		"charity_password": "",
		"charity_phone_number": "",
		"charity_phone_prefix": "",
		"members_quantity": "",
		"address": "",
		"first_name_contact_person": "",
		"last_name_contact_person": "",
		"promoted_by_code": ""
	});
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleInformationPopUp = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseInformationPopUp = () => {
	setAnchorEl(null);
	};

	const openPopUp = Boolean(anchorEl);
	const idPopUp = openPopUp ? 'simple-popover' : undefined;
	
	useEffect(() => {
		if ( success ) {
			navigate(
				'/login'
			);
		}
	  }, [success, newUserData, navigate]);

	useEffect(() => {
		setError("");
	}, [newUserData]);  

	useEffect(() => {
		if( data ) {
			if( data.errorMessage ) {
				setError(data.errorMessage);
			} else {
				setError("");
				setSuccess(true);
			}
        }
	}, [data]); 

	const handleSubmit = async (e) => {
		e.preventDefault();

		const resultError = validateForm(newUserData, passwordConfirmation);

		if (resultError !== null) {
			setError(resultError);
			return;
		}

		createNewCharity( adaptCharityCreationBody(newUserData) ).then(response => setData(response.data));

	};

	const onChangeUserAddress = (newValue)=>{
		setUserData((previous) => ({...previous, address: newValue}))
	}

	const formData = [
		{ 
		  label: 'Charity Name *', value: newUserData.charity_name, 
		  onChange: (e) => setUserData((previous) => ({...previous, charity_name: e.target.value})), 
		  type: 'text' 
		},
		{ 
			label: 'Charity ABN Number *', value: newUserData.abn, 
			onChange: (e) => setUserData((previous) => ({...previous, abn: e.target.value})),
			type: 'text' 
		},
		{ 
			label: 'Charity Number *', value: newUserData.charity_number, 
			onChange: (e) => setUserData((previous) => ({...previous, charity_number: e.target.value})),
			type: 'text' 
		},
		{ 
			label: 'Website *', value: newUserData.website, 
			onChange: (e) => setUserData((previous) => ({...previous, website: e.target.value})),
			type: 'text' 
		},
		{ 
			label: 'Category *', value: newUserData.category, 
			onChange: (e) => setUserData((previous) => ({...previous, category: e.target.value})), 
			type: 'select',
			options: [
				{
					datavalue: "charity",
					value: "charity",
					label: "Charity",
					key: "option-charity"
				},
				{
					datavalue: "church",
					value: "church",
					label: "Church",
					key: "option-church"
				},
				{
					datavalue: "club",
					value: "club",
					label: "Club",
					key: "option-club"
				},
				{
					datavalue: "other",
					value: "other",
					label: "Other non for profit",
					key: "option-other-non-for-profit"
				}
			]
		},
		{ 
			label: 'Number of Members *', value: newUserData.members_quantity, 
			onChange: (e) => setUserData((previous) => ({...previous, members_quantity: e.target.value})), 
			type: 'select',
			options: [
				{
					datavalue: "0-1000",
					value: "0-1000",
					label: "0-1000",
					key: "option-0-1000"
				},
				{
					datavalue: "1k-5k",
					value: "1k-5k",
					label: "1k-5k",
					key: "option-1k-5k"
				},
				{
					datavalue: "5k-10k",
					value: "5k-10k",
					label: "5k-10k",
					key: "option-5k-10k"
				},
				{
					datavalue: "10k-20k",
					value: "10k-20k",
					label: "10k-20k",
					key: "option-10k-20k"
				},
				{
					datavalue: "20k-30k",
					value: "20k-30k",
					label: "20k-30k",
					key: "option-20k-30k"
				},
				{
					datavalue: "30k-40k",
					value: "30k-40k",
					label: "30k-40k",
					key: "option-30k-40k"
				},
				{
					datavalue: "40k-50k",
					value: "40k-50k",
					label: "40k-50k",
					key: "option-40k-50k"
				},
				{
					datavalue: "500k+",
					value: "500k+",
					label: "500k+",
					key: "option-500k+"
				}
			]
		},
		{ 
			label: 'Address (No PO BOX) *', value: newUserData.address, 
			onChange: (e) => setUserData((previous) => ({...previous, address: e.target.value})), 
			type: 'address' 
		},
		{ 
		  label: 'Contact Person First Name *', value: newUserData.firts_name_contact_person, 
		  onChange: (e) => setUserData((previous) => ({...previous, first_name_contact_person: e.target.value})),
		  type: 'text' 
		},
		{ 
			label: 'Contact Person Last Name *', value: newUserData.last_name_contact_person, 
			onChange: (e) => setUserData((previous) => ({...previous, last_name_contact_person: e.target.value})),
			type: 'text' 
		},
		{ 
		  label: 'Contact Person Email *', value: newUserData.charity_email, 
		  onChange: (e) => setUserData((previous) => ({...previous, charity_email: e.target.value})), 
		  type: 'email' 
		},
		{ 
		  label: 'Password', value: newUserData.charity_password, 
		  onChange: (e) => setUserData((previous) => ({...previous, charity_password: e.target.value})), 
		  type: 'password' 
		},
		{ 
		  label: 'Confirm Password', value: passwordConfirmation, 
		  onChange: (e) => setPasswordConfirmation( e.target.value ), 
		  type: 'password' 
		},
		{ 
			label: 'Phone Number Country *', value: newUserData.charity_phone_prefix, 
			onChange: (e) => setUserData((previous) => ({...previous, charity_phone_prefix: e.target.value})), 
			type: 'select',
			options: [
				{
					datavalue: "AU",
					value: "+61",
					label: "Australia (+61)",
					key: "option-au"
				},
				{
					datavalue: "NZ",
					value: "+64",
					label: "New Zeland (+64)",
					key: "option-nz"
				},
				{
					datavalue: "SGP",
					value: "+65",
					label: "Singapore (+65)",
					key: "option-sgp"
				}
			]
		},
		{ 
		  label: 'Phone Number *', value: newUserData.charity_phone_number,
		  onChange: (e) => setUserData((previous) => ({...previous, charity_phone_number: e.target.value})), 
		  type: 'text' 
		},
		{ 
			label: 'BDM Referral Code (Optional)', value: newUserData.promoted_by_code, 
			onChange: (e) => setUserData((previous) => ({...previous, promoted_by_code: e.target.value})), 
			type: 'text',
			information_link: 'Details',
			information_text: 'If a BDM has referred you, it is important their referral number is entered here as can not be done at a later date.  Your BDM should have given this to you, if you do not have it, please check with them before proceeding.   If you have not been referred by a BDM, please leave blank.'
		}
	  ];
	return (
			<Container>
				<FormRow>
					<FormColumn>
						<FormWrapper onSubmit={handleSubmit}>
							{formData.map((el, index) => (
								<FormInputRow key={index}>
								<FormLabel class={el.class}>
									{el.label}
								</FormLabel>
								{
									(el.type !== "select" && el.type !== "address" && !el.information_link)&& 
									(
										<FormInput
											type={el.type}
											placeholder={`Enter ${el.label}`}
											value={el.value}
											onChange={el.onChange}
										/>
									)
								}
								{
									(el.information_link) &&
									(
										<Stack direction="row" spacing={1}>
											<FormInput
												type={el.type}
												placeholder={`Enter ${el.label}`}
												value={el.value}
												onChange={el.onChange}
											/>
											<IconButton color="primary" aria-label="information" onClick={handleInformationPopUp}>
												<InfoIcon />
											</IconButton>
											<Popover
												id={idPopUp}
												open={openPopUp}
												anchorEl={anchorEl}
												onClose={handleCloseInformationPopUp}
												anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
												}}
											>
												<Typography variant="caption">{el.information_text}</Typography>
											</Popover>
										</Stack>
									)
								}
								{
									el.type === "select" && 
									(
										<FormSelectDiv>
											<Select sx={{ m: 1, minWidth: 250, maxHeight: 25 }}
												//labelId="demo-simple-select-label"
												//id="demo-simple-select"
												//value={age}
												//label="Age"
												onChange={el.onChange}
												defaultValue=""
											>
												{
													el.options.map((option, index) => (
														<MenuItem value={option.value} key={option.key}>{option.label}</MenuItem>
													))
												} 
											</Select>
										</FormSelectDiv>
									)
								}
								{
									el.type === "address" && 
									(
										<AutocompleteAddressComponent
											onChangeUserAddress={onChangeUserAddress}
										/>
									)
								}
							</FormInputRow>
							))}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Submit
                            </Button>
						</FormWrapper>
						{error !== "" && (
							<FormErrorMessage>
								{error}
							</FormErrorMessage>
						)}
					</FormColumn>
				</FormRow>
			</Container>
	);
};

export default Form;
