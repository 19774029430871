import {
  StyledContainer,
  StyledDivPage,
} from "../../../components/common/StyledSection";
import { Grid } from "@mui/material";
import ProfilePhoto from "./ProfilePhoto";
import ProfileTable from "./ProfileTable";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { getCharityInformation } from "../../../api/UserService";
import {Link} from 'react-router-dom';

const CharityProfilePage = () => {
  const [CharityData, setCharityData] = useState<any | null>(null);

  useEffect(() => {
    // Can implement a <Loading/>
    //  start loading
    if (!CharityData) {
      getCharityInformation().then((data) => setCharityData(data));
    }
    //  finish loading
  }, [CharityData, setCharityData]);

  const charity = {
    name: CharityData?.charity_name,
    first_name_contact: CharityData?.first_name_contact_person,
    last_name_contact: CharityData?.last_name_contact_person,
    address: CharityData?.charity_address,
    city: CharityData?.charity_city,
    state: CharityData?.charity_state,
    postal_code: CharityData?.charity_postal_code,
    country: CharityData?.charity_country,
    email: CharityData?.charity_email,
    phone: CharityData?.charity_phone_number,
    members_quantity: CharityData?.members_quantity
  };

  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">PROFILE</div>
        <div className="subtitle">Details</div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ProfilePhoto {...charity.name} />
            <Link to="/changepassword">
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 2, mb: 2, width: { xs: "75%", md: "80%" }, height: { xs: "20%", md: "10%" } }}
              >
                Change password
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfileTable {...charity}/>
          </Grid>
        </Grid>
      </StyledDivPage>
    </StyledContainer>
  );
};

export default CharityProfilePage;
