import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface IProps {
    volume: string,
    volumeName: string
}

function createData(
  name: string,
  amount: string,
  measurement: string,
) {
  return { name, amount, measurement };
}

export default function SalesTable( props: IProps ) {
    const rows = [
        createData(props.volumeName, props.volume, 'CV'),
        createData('Transactional Volume', '0', 'CV'),
        createData('Network Volume', '126', 'CV'),
        ];  
    return (
        <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.amount + ' ' + row.measurement}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}