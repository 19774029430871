import React from 'react'
import { Outlet } from 'react-router-dom';

type Props = {}

const InformationIndex = (props: Props) => {
    return (
        <><Outlet /></>
      );
};

export default InformationIndex;