import { CardBody } from './styles';

interface IProps {
    value: string
    bgColor: string
}

export default function CardComponentBody( props: IProps ) {
    return (
        <CardBody $inputColor={props.bgColor}>
            <div>erth {props.value}</div>
        </CardBody>
    )
};