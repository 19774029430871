import Header from './InformationCardComponentHeader';
import CardBody from './InformationCardComponentBody';

interface IProps {
    headerTitle: string,
    bodyValue: string,
    bodyIcon: string,
    bgHeaderColor: string,
    bgBodyColor: string
}

export default function CardComponent(props: IProps) {
    return (
        <>
            <Header 
                title= {props.headerTitle}
                bgColor={props.bgHeaderColor}
            />
            <CardBody 
                value= {props.bodyValue}
                icon= {props.bodyIcon}
                bgColor= {props.bgBodyColor}
            />
        </>
    );
};