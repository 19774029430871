import React from 'react'
import { Outlet } from 'react-router-dom';

type Props = {}

const NetworkIndexPage = (props: Props) => {
    return (
        <><Outlet /></>
      );
};

export default NetworkIndexPage;