import { Outlet } from "react-router-dom";
import sizeConfigs from "../../configs/sizeConfigs";
import Menu from "../common/Menu";
import { Avatar, Box, Toolbar, AppBar, Typography } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import Stack from '@mui/system/Stack';

const MainLayout = () => {
  const user_type = localStorage.getItem("user_type");
  return (
    <Stack spacing={2}>
      <AppBar position="sticky" sx={{ backgroundColor: colorConfigs.sidebar.bg }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography variant="h6" noWrap>
              {
                user_type === "ambassador" ? "BDM's" : "MLM CHARITIES"
              }
            </Typography>
            <Avatar 
              src={assets.images.logo} 
              sx={{ justifyContent: "flex-end" }}
            />
          </Toolbar>
      </AppBar>
      <div style={{ margin: '-8px 0' }}></div>
      <Box sx={{ display: "flex" }}>
        <Menu />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: "0px", sm: "0px" },
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            minHeight: "100vh"
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
};

export default MainLayout;