import CardHeader from './CourseCardComponentHeader';
import CardBody from './CourseCardComponentBody';
import CardSection from './CourseCardComponentSection';
import CardComponentButtonSection from './ButtonCardComponentSection';

interface IProps {
    headerTitle: string,
    bodyValue: string,
    bgBodyColor: string,
    firstBodySection: string,
    secondBodySection: string,
    thirdBodySection: string,
    linkToBuy: string
}

export default function CardComponent(props: IProps) {
    return (
        <>
        <div style={{ borderStyle: 'solid', minWidth: '238px' }}>
            <CardHeader 
                title= {props.headerTitle}
            />
            <CardBody 
                value= {props.bodyValue}
                bgColor= {props.bgBodyColor}
            />
            <CardSection 
                text= {props.firstBodySection}
            />
            <CardSection 
                text= {props.secondBodySection}
            />
            <CardSection 
                text= {props.thirdBodySection}
            />
            <CardComponentButtonSection 
                linkToBuy= {props.linkToBuy}
            />
        </div>    
        </>
    );
};