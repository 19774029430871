import React from 'react'
import { StyledContainer, StyledDivPage } from "../../../components/common/StyledSection";
import TreeGraph from './TreeGraphComponent';

const NetworkChartPage = () => {
  return (
    <StyledContainer>
      <StyledDivPage>
        <div className="title">NETWORK CHART</div>
        <div className="subtitle">Here you can navigate through your downline in tree view mode</div>
        <div style={{ margin: '20px 0' }}></div>
        <TreeGraph />
      </StyledDivPage>
    </StyledContainer>
  );
};

export default NetworkChartPage;