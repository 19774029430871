import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButtonsGroupComponent(props) {
  return (
    <FormControl>
      <FormLabel id="row-radio-buttons-group-label">Select user type</FormLabel>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e)=>{props.onChangeFunction(e.target.value)}}
      >
        <FormControlLabel value="ambassador" control={<Radio />} label="BDM - Business Development Manager" />
        <FormControlLabel value="charity" control={<Radio />} label="Charity" />
      </RadioGroup>
    </FormControl>
  );
}