import { getSuggestions } from '../../api/autocomplete-address';
import { useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from "react";

export function AutocompleteAddressComponent({onChangeUserAddress}) {

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');

    const onChangeHandle = async value => {
        await getSuggestions( inputValue ).then( ( data ) => setOptions( data ? data.items : []) )
    };

    function getAddressFormat(address){
        if(address){
            return address.houseNumber + ' ' + address.street + ', ' + address.district + ', ' + address.postalCode + ', ' + address.stateCode + ', ' + address.countryName
        } else {
            return null
        }
    }

    return (
        <Stack spacing={1} sx={{ width: { sx: '200', sm: '550' } }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    onChangeUserAddress(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    
                }}
                getOptionLabel={(item) => getAddressFormat(item.address) || ''}
                options={options}
                id="field-autocomp"
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                        label="Enter your address" 
                        variant="standard"
                        onChange={ev => {
                                // dont fire API if the user delete or not entered anything
                                if (ev.target.value !== "" || ev.target.value !== null) {
                                    onChangeHandle(ev.target.value);
                                }
                        }}
                    />
                }
            />
        </Stack>
    );
};
