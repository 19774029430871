import React from 'react'
import { Outlet } from 'react-router-dom';

type Props = {}

const PayoutIndexPage = (props: Props) => {
    return (
        <><Outlet /></>
      );
}

export default PayoutIndexPage