import axios from 'axios';

const APIKEY = 'xOPmxLRbqJCkgdtL-WR8DBu4ji90egZXXeH5K0MQZD4';
const BASE_URL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';


export const getSuggestions = async (value) => {

    const params = '?' +
                    'q=' +  value +
                    '&in=countryCode%3AAUS' +
                    '&limit=10' + 
                    '&apikey=' + APIKEY
                ;
    try {
      const { data } = await axios.get( BASE_URL + params );

      console.log("autocomplete data:", data);
      return data;
    } catch (errors) {
      console.error(errors);
    }
};