import { getWithExpiry } from '../context/MyLocalStorage';
import Login from '../pages/login/Login';
import { InvalidCharitiesRoute } from './InvalidCharitiesRoute';

export const CharitiesPrivateRoute = ({ children }) => {
    const token = getWithExpiry("token");
    const user_type = localStorage.getItem("user_type");

    if (!token) {
      // user is not authenticated
      return <Login />;
    }

    if(user_type !== "charity") {
      // user is not authenticated as an ambassador
      return <InvalidCharitiesRoute />
    }
    
    return children;
};
