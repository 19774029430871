import {TableContainer, Table, TableHeaderCell, TableCell, TableRow} from './TableStyles'
import Pagination from './Pagination';
import { useMemo, useState } from "react";

let PageSize = 10;

type Props = {
  columns: {
    [key: string]: {
      key: string,
      label?: string,
      align?: string,
      width?: number,
      content?: Function,
      css?: string,
      cssHeader?: string,
      hideondesktop?: string,
      hideontablet?: string,
      hideonphone?: string
    }
  },
  data?: Array<Object>,
  rowConfig?: {
    uniqueKey?: string,
    css?: string,
    onClick?: Function
  }
};

const StyledTable = ({
  columns,
  data = [],
  rowConfig: { uniqueKey = "id", css , onClick } = {}
}: Props) => {

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);

  const headerColumns = () =>
    Object.keys(columns).map(key => (
      <TableHeaderCell
        key={key}
        align={columns[key].align}
        width={columns[key].width}
        hideondesktop={columns[key].hideondesktop}
        hideontablet={columns[key].hideontablet}
        hideonphone={columns[key].hideonphone}
        css={columns[key].cssHeader}
      >
        {columns[key].label ? columns[key].label : ""}
      </TableHeaderCell>
    ));
  const cell = (key, item) => (
    <TableCell
      key={key}
      align={columns[key].align}
      width={columns[key].width}
      hideondesktop={columns[key].hideondesktop}
      hideontablet={columns[key].hideontablet}
      hideonphone={columns[key].hideonphone}
      css={columns[key].css}
    >
      {columns[key].content ? columns[key].content(item) : item[key]}
    </TableCell>
  );
  const row = (item: Object) => (
    <TableRow
      key={JSON.stringify(item)}
      css={css}
      onClick={onClick ? (e: Event) => onClick(e, item) : null}
    >
      {Object.keys(columns).map(key => cell(key, item))}
    </TableRow>
  );

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>{headerColumns()}</tr>
        </thead>
        <tbody>{currentTableData?.map(i => row(i))}</tbody>
      </Table>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
    </TableContainer>
  );
};

export default StyledTable;