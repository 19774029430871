import { Component } from 'react';

class Checkbox extends Component {
    constructor(props, context) {
      super(props, context);

      this.state = {
        checked: this.props.item.selected
      };

      this.toggleChecked = this.toggleChecked.bind(this);
    }

    toggleChecked() {
        if(!this.props.item.selected) {
            this.props.item.selected = !this.props.item.selected;
            this.props.totalAmountSetter( this.props.totalAmount + Number(this.props.item.amount) );
    
            this.setState(prevState => ({ checked: this.props.item.selected }));
        }
    }
  
    render() {
        return <input
            type='checkbox'
            name='public'
            checked={this.props.item.selected}
            onChange={this.toggleChecked}
        />
    }
}

export default Checkbox;