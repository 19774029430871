import { AxiosError } from 'axios';
import { axiosAuth, getHeaders } from './axios';

export function getCurrentPeriodSalesComponentTitle() {
    return 'CURRENT PERIOD SALES VOLUME';
};

export function getTotalSalesComponentTitle() {
    return 'TOTAL SALES VOLUME';
};

export const getCurrentPeriodSalesVolume = async (user_id = 0) => {

    if( !user_id ){
        //TODO
        //const user_id = localStorage.getItem("user_id");
        user_id = 37;
    }

    try {
        const TOTAL_SALES_VOLUME_URL = '/payments/' + user_id.toString() + '/total?from=';
        const CURRENT_PERIOD_SALES_VOLUME_URL = TOTAL_SALES_VOLUME_URL + getCurrentWeekFilter();

        const { data } = await axiosAuth.get(CURRENT_PERIOD_SALES_VOLUME_URL, {headers: getHeaders()})

        return data
    } catch (err) {
        const error = err as AxiosError
        const data = error.response?.data as any
        if(data?.error === "No payment found") {
            return {total: 0}
        }
        else {
            console.error(error);
        }
        
    }
}

export const getTotalSalesVolume = async () => {

    //TODO
    //const user_id = localStorage.getItem("user_id");
    //const TOTAL_SALES_VOLUME_URL = '/payments/' + user_id.toString() + '/total';
    try {
        const TOTAL_SALES_VOLUME_URL = '/payments/37/total';

        const { data } = await axiosAuth.get(TOTAL_SALES_VOLUME_URL, {headers: getHeaders()})

        return data
    } catch (err) {
        const error = err as AxiosError
        const data = error.response?.data as any
        if(data?.error === "No payment found") {
            return {total: 0}
        }
        else {
            console.error(error);
        }
        
    }
}

function getCurrentWeekFilter() {
    const dateFormat = "YYYY-MM-DD";
    var prevMonday = new Date();
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);

    const formattedDate = dateFormat
                            .replace( 'YYYY', prevMonday.getFullYear().toString() )
                            .replace( 'MM', (prevMonday.getMonth() + 1).toString() )
                            .replace( 'DD', prevMonday.getDate().toString() );

    return formattedDate;
};