import { CardSection } from './styles';

interface IProps {
    text: string
}

export default function CardComponentSection( props: IProps ) {
    return (
        <CardSection>
            <div>{props.text}</div>
        </CardSection>
    )
};